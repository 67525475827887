import React from "react";
import ImgColKlavesnica from "../../shared/ImgColKlavesnica";
import ImgColKamera from "../../shared/ImgColKamera"
import LogoLinkHome from "../../shared/LogoLinkHome";
import KontaktInfo from "../../shared/KontaktInfo";


const styleWrap = {
  display: "contents"
}

const LeftC = () => (
  <div style={styleWrap}>
    <LogoLinkHome />
    <KontaktInfo />
    <ImgColKamera />
    <ImgColKlavesnica />
  </div>
);

export default LeftC;
