import React from "react";

import CenaInstalacie from "./CenaInstalacie.js"
import Obhliadka from "./Obhliadka.js"
import PripravteSi from "./PripravteSi.js"
import Pc123Info from "./Pc123Info.js"
import MapaSidlo from './MapaSidlo';


const MainCUp = ({ myStyle }) => (
  <div>
    <CenaInstalacie />
    <Obhliadka />
    <PripravteSi />
    <Pc123Info />
    <MapaSidlo />
  </div>
);

export default MainCUp;