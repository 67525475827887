/** @jsx jsx */
import { Tabs, TabList, Tab, TabPanel } from "react-tabs"
import { jsx, } from "theme-ui";
import { alpha } from "@theme-ui/color";
import { keyframes } from '@emotion/react'

const styleTabs = {
  padding: `0.5rem 0 2rem 0`,
  "& a": {
    color: "textmuted"
  },
}

const styleTabList = {
  display: `flex`,
  justifyContent: ["flex-start", "center"],
  flexDirection: ["column", "column", "row"],
  flexWrap: `wrap`,
  padding: 0,
  margin: `0 0 0.5rem 0`,
  borderBottomColor: "secondary",
  borderBottomStyle: "solid",
  borderBottomWidth: "thin",
  marginBottom: 0,
}

const styleTab = {
  borderRadius: '2px',
  listStyle: `none`,
  cursor: `pointer`,
  py: 1,
  px: "1rem",
  m: 1,
  color: "textmuted",
  bg: alpha("gray.5", 0.2),
  transition: `all 0.3s ease-in-out`,
  "&:not(.selected):hover": {
    backgroundColor: `rgba(0, 0, 0, 0.1)`,
  },
  "&.selected": {
    backgroundColor: "secondary",
    color: "headertext",
    marginBottom: 0,
    paddingBottom: 0,
    borderBottomWidth: 0,
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
  variant: "styles.h4"
}

const styleTabBig = {
  listStyle: `none`,
  cursor: `pointer`,
  background: `none`,
  padding: `0.3rem 1rem`,
  color: "textmuted",
  fontWeight: 500,
  fontSize: `1.3rem`,
  borderRadius: "5px",
  transition: `all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s`,
  "&:not(.selected):hover": {
    background: `rgba(0, 0, 0, 0.1)`,
  },
  "&.selected": {
    background: `rgba(0, 0, 0, 0.1)`,
    color: "text",
  },
}


const fadeIn = keyframes({ from: { opacity: 0 }, to: { opacity: 1 } })

const styleTabPanelNavigation = {
  display: `none`,

  borderStyle: "solid",
  borderWidth: "thin",
  borderColor: "secondary",
  borderTop: "none",

  "&.selected": {
    display: `block`,
    animationName: `${fadeIn}`,
    animationDuration: `0.2s`,
    animationTimingFunction: `linear`,

  },
}

const styleTabPanelGrid = {
  display: `none`,
  "&.selected": {
    display: `grid`,
    gridTemplateColumns: `1fr`,
    gap: `1rem`,
    animationName: `${fadeIn}`,
    animationDuration: `0.2s`,
    animationTimingFunction: `linear`,
    "@media": (theme) => {
      return {
        [`screen and (min-width: ${theme.breakpoints.sm})`]: {
          gridTemplateColumns: `repeat(2, 1fr)`,
          gap: `1.5rem`,
        },
        [`screen and (min-width: ${theme.breakpoints.lg})`]: {
          gridTemplateColumns: `repeat(3, 1fr)`,
          gap: `2.5rem`,
        }
      }
    },
  },
  // },
}

const TabsR = ({ children, ...rest }) => (
  <Tabs
    {...rest}
    sx={styleTabs}
  >
    {children}
  </Tabs>
)
const TabListR = ({ children, ...rest }) => (
  <TabList
    {...rest}
    sx={styleTabList}
  >
    {children}
  </TabList>
)
const TabR = ({ children, ...rest }) => (
  <Tab
    selectedClassName="selected"
    disabledClassName="disabled"
    {...rest}
    sx={{
      ...styleTab,
    }}
  >
    {children}
  </Tab>
)
const TabBigR = ({ children, ...rest }) => (
  <Tab
    selectedClassName="selected"
    disabledClassName="disabled"
    {...rest}
    sx={styleTabBig}
  >
    {children}
  </Tab>
)

const TabPanelR = (props) => {
  const { children, isNavigation = false, ...rest } = props
  return (
    <TabPanel
      selectedClassName="selected"
      {...rest}
      sx={isNavigation ? styleTabPanelNavigation : styleTabPanelGrid}
    >
      {children}
    </TabPanel>
  )
}

TabR.tabsRole = `Tab`
TabBigR.tabsRole = `Tab`
TabsR.tabsRole = `Tabs`
TabPanelR.tabsRole = `TabPanel`
TabListR.tabsRole = `TabList`

export { TabsR, TabListR, TabR, TabBigR, TabPanelR }
