
import React, { memo, lazy, Suspense } from "react";

const MyLazyWeb7skHtml5Banner = lazy(() => import(
  /* webpackMode: "lazy" */ /* webpackChunkName: 'LazyWeb7skHtml5Banner' */
  "./Web7skHtml5Banner"))


const LazyWeb7skHtml5Banner = (props) => (
  <Suspense fallback={<div className="loader" />}>
    <MyLazyWeb7skHtml5Banner {...props} />
  </Suspense>
);
export default memo(LazyWeb7skHtml5Banner);
