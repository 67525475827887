/** @jsx jsx */
import { Image, jsx } from "theme-ui";
import LogoLinkHome from "../../shared/LogoLinkHome";
import KontaktInfo from "../../shared/KontaktInfo";
import mobil from '../../../images/alarmy/fixed/mobil-trans-70.png';

const styleImgWrap = {
  display: ["none", "block", null, null],
  p: [null, 0, 1, 2],
  m: [null, 0, 1, 2],
};

const styleWrap = {
  display: "contents"
}

const LeftC = ({ myStyle }) => (
  <div style={styleWrap}>
    <LogoLinkHome />
    <KontaktInfo />
    <div sx={{ ...styleImgWrap, ...myStyle }} >
      <Image alt="sledovanie-alarmov-na-mobile" src={mobil} />
    </div>
  </div>
);

export default LeftC;
