/** @jsx jsx */
import { jsx, Image, Box, Grid } from "theme-ui";
// import { Themed } from '@theme-ui/mdx'
import imgVratnikDverovaStanica from "../../../images/videovratniky/dverova-stanica.png"

const headerStyle = {
    mb: 1,
};

const defaultStyle = {
    mx: 0,
    borderColor: "borderscolor",
    borderStyle: "ridge",
    borderWidth: "1px",
    backgroundColor: "accent",
    flexDirection: "column",
};


const VratnikDverovaStanica = ({ myStyle }) => {

    return (<Box sx={{
        my: ["2rem", "4rem", "6rem"]
    }}>
        <h4 sx={{ variant: "styles.h4", ...headerStyle }}  >Dverová stanica</h4>
        <Grid gap={2} columns={[1, "3fr 9fr"]} sx={{ p: ["0.5rem", "1rem",] }} >
            <Image src={imgVratnikDverovaStanica} sx={{ margin: "0 auto " }} alt="Videovrátnik - vnútorná stanica" />

            <Box as="section" sx={{ ...defaultStyle, ...myStyle }} >
                <ul>
                    <li>1.3 MP alebo 2MP HD kamera s IR prisvietením (pre kvalitný obraz aj v noci) + WDR technológia</li>
                    <li>využíva elektrický/magnetický zámok na dverách</li>
                    <li>zvoní = volá sa z nej k vnútornej stanici upevnenej v miestnosti u rezidenta + možnosť poslania do cloudu do Hik-Connect app na mobile (zvonenie).</li>
                    <li>komunikácia (čistý zvuk - zabudované potlačenie okolitého šumu a echa)</li>
                    <li>k dispozícii viac verzií All-In-One podľa zabudovaných funkcií a kvality</li>
                    <li>možné vyskladanie dverovej stanice podľa zvolených modulov</li>
                    <li>modul (prívetivý pre návštevníka) s prehľadnou indikáciou stavu dverovej stanice - indikácia volania, otvárania dverí, dvojcestnej zvukovej komunikácie</li>
                    <li>modul s klasickými menovkami (rezidentov) a tlačidlami na zvonenie = volanie</li>
                    <li>modul s fyzickou klávesnicou na volanie rezidentovi, + rezident môže zadať odomykacie pin číslo</li>
                    <li>modul s displejom + 4 tlačidlami na zobrazenie zoznamu rezidentov a výber z neho, na zadanie odomykacieho pin čísla</li>
                    <li>modul s čítačkou bezkontaktných kariet (rezidenti si tak môžu otvoriť dvere)</li>
                    <li>možnosť zaznamenávania prechodov cez dvere - číslo karty, krátky videoklip, logy a ďalšie (can be uploaded to the platform centre), z ktorých možno spraviť report</li>
                    <li>možnosť pripojenia rôznych druhov detektorov napr. vniknutia a narušenia vyvolajú odoslanie push notifikácie na HikConnect aplikáciu v mobile</li>
                </ul>
                <ul>
                    Ďalšie možné príslušenstvo
                    <li>exit tlačidlo (pri odchode stlačiť na otvorenie dverí)</li>
                    <li>elektrický/magnetický zámok</li>
                </ul>

            </Box>
        </Grid>
    </Box>
    )
}

export default VratnikDverovaStanica