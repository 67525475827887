import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import useSiteMetadata from "../hooks/use-site-metadata";

const removeNulledKeyAndCorrect_ = (obj) => {
  let newObj = {};
  Object.keys(obj).forEach((key) => {
    if (Array.isArray(obj[key])) {
      newObj[key] = obj[key]
      return
    }
    if (obj[key] === Object(obj[key])) newObj[key] = removeNulledKeyAndCorrect_(obj[key]);
    else if (obj[key] != null) {

      switch (true) {
        case (key === "_type"):
          newObj["@type"] = obj[key]
          break

        case (key === "_context"):
          newObj["@context"] = obj[key]
          break

        default:
          newObj[key] = obj[key]
          break;

      }
    }

  });
  return newObj;
};


const Seo = ({ seoSpecialForPage, googleStructuredData, aggregateRating, seoImages, path, pathname = "" }) => {

  const siteMetaData = useSiteMetadata();

  const {
    title,
    siteUrl,
    description,
    keywords,
    siteImage,
    locale,
  } = siteMetaData;

  if (seoSpecialForPage) seoSpecialForPage.canonicalPath = path;

  if (googleStructuredData) {

    if (!!aggregateRating) {
      googleStructuredData.aggregateRating = aggregateRating
    }

    if (seoImages) {
      const seoImagesPaths = seoImages.nodes.map(
        (node) => `${siteUrl}/${node.base}`
      );
      googleStructuredData.image = seoImagesPaths;
    }
  }



  let googleStructuredDataCorrected
  let ggleSiteStructuredData = siteMetaData.ggleSiteStructuredData;
  if (googleStructuredData) {

    const {
      providerType,
      addressType,
      schemaContext,
      ...googleSiteStructuredData
    } = ggleSiteStructuredData;
    googleSiteStructuredData["@context"] = schemaContext;
    googleSiteStructuredData.provider["@type"] = providerType;
    googleSiteStructuredData.provider.address["@type"] = addressType;

    const { isProvider, ...restGoogleStructuredData } = googleStructuredData;
    if (isProvider) {
      const {
        provider,
        ...googleSiteStructuredDataWithoutProvider
      } = googleSiteStructuredData;
      googleStructuredData = {
        ...restGoogleStructuredData,
        ...googleSiteStructuredDataWithoutProvider,
        ...provider,
      };
    } else {
      googleStructuredData = {
        ...googleStructuredData,
        ...googleSiteStructuredData,
      };
    }

    googleStructuredDataCorrected = removeNulledKeyAndCorrect_(googleStructuredData)
  }
  const canonicalPath = `${siteUrl}${pathname}${seoSpecialForPage.canonicalPath}`

  return (
    <Fragment>
      <Helmet
        defer={false}
        defaultTitle={seoSpecialForPage.title || title}
        titleTemplate={`%s || ${title}`}
      >
        <html lang={seoSpecialForPage.lang || locale} />
        <link
          rel="canonical"
          href={canonicalPath}
        />
        <meta
          name="description"
          content={seoSpecialForPage.description || description}
        />
        <meta name="keywords" content={seoSpecialForPage.keywords || keywords} />
        <meta property="og:title" content={seoSpecialForPage.title || title} />

        <meta name="docsearch:version" content="2.0" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
        />
        <meta property="og:url" content={siteUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content={seoSpecialForPage.lang || locale} />
        <meta property="og:site_name" content={title} />
        <meta property="og:image" content={`${siteUrl}${siteImage}`} />
        {googleStructuredDataCorrected && (
          <script type="application/ld+json">
            {JSON.stringify(googleStructuredDataCorrected)}
          </script>
        )}
      </Helmet>
    </Fragment>
  );
};
export default Seo;
