/** @jsx jsx */
import { jsx } from "theme-ui";
import { Image } from "theme-ui";
import {
  useBreakpointIndex
} from '@theme-ui/match-media'
import { Link } from "gatsby";
import { LightgalleryItem } from "react-lightgallery";
import MyReactMultiCarousel2 from "../../shared/myReactMultiCarousel/MyReactMultiCarousel2";
import { BREAKPOINTS } from "../../../constants"
import { useStaticKameryPraceImages } from "./useStaticKameryPraceImages"

const sectionStyle = {
  borderColor: "borderscolor",
  borderStyle: "ridge",
  borderWidth: "1px",
  px: [1, 2, 3, 4],
  pt: 0,
  pb: [1, 2, 3, 4],
};

const themeBreakpointsValues = Object.entries(BREAKPOINTS).map(([key, val]) => val);
const themeBreakpointsKeys = Object.entries(BREAKPOINTS).map(([key, val]) => key);

const responsive = {
  xl: {
    breakpoint: {
      max: 8000,
      min: themeBreakpointsValues[4] + 1,
    },
    items: 5,
  },
  lg: {
    breakpoint: {
      max: themeBreakpointsValues[4],
      min: themeBreakpointsValues[3] + 1,
    },
    items: 3,
  },
  md: {
    breakpoint: {
      max: themeBreakpointsValues[3],
      min: themeBreakpointsValues[2] + 1,
    },
    items: 2,
  },
  sm: {
    breakpoint: {
      max: themeBreakpointsValues[2],
      min: themeBreakpointsValues[1] + 1,
    },
    items: 1,
  },
  xs: {
    breakpoint: {
      max: themeBreakpointsValues[1],
      min: 0,
    },
    items: 1,
  },
}


const KameryZNasichPracReactMultiCarouselLightGallery = () => {
  const currentMatchedBreakpointIndex = useBreakpointIndex()
  const deviceType = themeBreakpointsKeys[currentMatchedBreakpointIndex]
  const autoPlay = deviceType !== "xs" ? false : false
  const infinite = deviceType !== "xs" ? true : true
  const multiCarouselOptions = { responsive, deviceType, autoPlay, infinite }

  const group = "zNasichPrac"
  const kameryPraceImagesNodes = useStaticKameryPraceImages();
  const arrKameryZNasichPracImagesOrig = kameryPraceImagesNodes.map(
    (imgNode, i) => {
      const { src } = imgNode.childImageSharp.original
      const thumb = imgNode.childImageSharp.thumb100.src
      const key = imgNode.base

      const srcSlider = imgNode.childImageSharp.imgSlider.src
      const alt = imgNode.base

      return (
        <LightgalleryItem
          group={group}
          src={src}
          thumb={thumb}
          key={key}
          subHtml={`<div>${imgNode.base}</div>`}
        >
          <Image
            src={srcSlider}
            alt={alt}
            variant="slideImg"
          />
        </LightgalleryItem>
      );
    }
  );

  const reversedColorsStyledLink = {
    color: "primary",

    "&::after": {
      color: "transparent",
      background: (t) => `${t.colors.headerlink}`,
    },
    "&:hover": {
      color: "primaryhover",
    },
    "&.active": {
      color: "primaryagainst",
    },
  };

  return (
    <div sx={{ my: "2rem" }}>
      <section sx={sectionStyle}>
        <Link
          activeClassName="active"
          sx={{ variant: "links.styledLink", ...reversedColorsStyledLink }}
          to="/referencie/"
        >
          <h4 sx={{ variant: "styles.h4" }}>Z našich prác</h4>
        </Link>
        <MyReactMultiCarousel2
          multiCarouselOptions={multiCarouselOptions}
          mySlider="kameryPraceOrig"
        >
          {arrKameryZNasichPracImagesOrig}
        </MyReactMultiCarousel2>
      </section>
    </div>
  );
};

export default KameryZNasichPracReactMultiCarouselLightGallery;
