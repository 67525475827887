/** @jsx jsx */
import { Box, jsx } from "theme-ui";
import VyzvaKObj from "./VyzvaKObj";
import KvalitaHik from "./KvalitaHik";
import PonukaSluzieb from "./PonukaSluzieb";


const outerStyle = {
  px: [1, 2, 3],
  mx: "auto",
  mb: [1, 2],
  py: [1, 2],
};


const Montaz = ({ myStyle }) => (
  <Box sx={{ ...outerStyle, ...myStyle }}>
    <h4 sx={{ variant: "styles.h4" }}>Montáž Hikvision kamerových systémov</h4>

    <Box>
      <p>
        Použitia kamerových systémov sú mnohostranné. Prevažne však ako CCTV
        systémy pre bezpečnosť osôb a na ochranu majetku a prevenciu.
      </p>
      <Box>
        Ukladajú videozáznam, umožňujú pozeranie živého obrazu alebo prehrávanie
        už uloženého záznamu. S analytickými funkciami videa kamery sa dá
        nastaviť napr. detekcia vniku osoby do monitorovaného priestoru - napr.
        dvora. K živému obrazu IP kamery sa dá priamo dostať cez internet napr.
        z mobilu (obmedzenia podľa nastavení).
      </Box>
      <hr />
      <Box>
        <p>
          Hikvision vyrába veľké množstvo kamier, s implementáciou rôznych
          hardvérových a softvérových technológií, s rôznou optikou.
        </p>
        Vo výsledku sú niektoré kamery špeciálnejšie, niektoré kamery
        pokrývajúce väčšie územie, niektoré kamery sú vhodné iba vo vnútri
        budovy. Kamery s excelentným obrazom za šera, kamery vhodné do
        protisvetla, kamery pre riešenia v úplnej tme, kamery aj pre automatické
        sledovanie pohybujúceho objektu, kamery určené do výbušného prostredia,
        kamery do korozívneho prostredia, kamery majúce až 16MP rozlíšenie,
        kamery podporujúce kompresiu videa až H265+.
      </Box>
      <div>
        K tomu prichádza rôzne množstvo inteligencie v kamerách pre detekciu
        udalostí v zosnímanom obraze pre spúšťanie alarmov a odosielanie
        notifikácií.
      </div>
      <div>
        Pre ukladanie obrazu od kamier má Hikvision k dispozícii opäť množstvo
        rekordérov s rôznymi ukladacími možnosťami a s rôznou dekódovacou
        schopnosťou.
      </div>
      <p>
        <a
          href="https://www.hikvision.com/cz/products/IP-Products/"
          target="_blank"
          rel="noreferrer"
        >
          Hikvision IP kamery
        </a>
      </p>
      <p>
        <a
          href="https://www.hikvision.com/content/hikvision/cz/newsroom/latest-news/2020/hikvision-launches-new-generation-of-acusense-products.html"
          target="_blank"
          rel="noreferrer"
        >
          Hikvision uvádí na trh novou generaci produktů AcuSense
        </a>
      </p>
      <p>
        <a
          href="https://www.hikvision.com/cz/core-technologies/deep-learning/acusense/"
          target="_blank"
          rel="noreferrer"
        >
          Technológia AcuSense. Posuňte svoje zabezpečenie na ďalšiu úroveň.
        </a>
      </p>
      <p>
        <a
          href="https://www.hikvision.com/cz/newsroom/latest-news/2021/discover-hikvision-s-latest-colorvu-technology--even-sharper-ima/"
          target="_blank"
          rel="noreferrer"
        >
          Objevte nejnovější technologii ColorVu společnosti Hikvision: ještě ostřejší obraz s jasnějšími barvami, 24 hodin denně
        </a>
      </p>
      <p>
        <a
          href="https://www.hikvision.com/cz/core-technologies/low-light-imaging/colorvu/"
          target="_blank"
          rel="noreferrer"
        >
          Farebné zobrazenie 24/7. Pozerajte se farebne, dokonca aj v tme. Čo je to technológia ColorVu Hikvision?
        </a>
      </p>
      <p>
        <a
          href="https://www.hikvision.com/cz/core-technologies/deep-learning/"
          target="_blank"
          rel="noreferrer"
        >
          Zvýšenie bezpečnosti a prevádzkovej efektívnosti s technológiou deep learning
        </a>
      </p>
      <p>
        <a
          href="https://www.hikvision.com/cz/core-technologies/high-definition-and-stable-imaging/"
          target="_blank"
          rel="noreferrer"
        >
          Ostrý a stabilný výkon obrazu s technologiiou 4K a Smooth Streaming
        </a>
      </p>
      <p>
        <a
          href="https://www.hikvision.com/cz/core-technologies/thermal-imaging/"
          target="_blank"
          rel="noreferrer"
        >
          Technológia termálneho zobrazovania
        </a>
      </p>
      <p>
        <a
          href="https://www.hikvision.com/cz/core-technologies/open-space-monitoring/"
          target="_blank"
          rel="noreferrer"
        >
          Obrie panorámy, neuveriteľné přiblíženie. Jediná kamera na širokú oblasť
        </a>
      </p>
      <p>
        <a
          href="https://www.hikvision.com/cz/core-technologies/storage-and-bandwidth/"
          target="_blank"
          rel="noreferrer"
        >
          Zladenie streamu, kompresie a úložného priestoru pre záznam
        </a>
      </p>
      <KvalitaHik />
      <VyzvaKObj />
      <PonukaSluzieb />
    </Box>
  </Box>
);
export default Montaz;
