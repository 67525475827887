import { useStaticQuery, graphql } from "gatsby";
/*
              fluid(maxWidth: 160) {
                aspectRatio
                ...GatsbyImageSharpFluid
              }
*/
const useMobilAlarmSettingsImages = () => {
  const data = useStaticQuery(
    graphql`
      {
        mobilAlarmSettingsImages: allFile(
          filter: {
            sourceInstanceName: { eq: "dirImages" }
            relativeDirectory: { eq: "galleries/alarm-na-mobile" }
          }
        ) {
          nodes {
            id
            base

            childImageSharp {
              id
              original {
                src
              }

              thumb100: resize(height: 100) {
                src
              }
              imgSlider: resize(height: 400) {
                src
              }

            }
          }
        }
      }
    `
  );
  return data.mobilAlarmSettingsImages.nodes;
};
export default useMobilAlarmSettingsImages;


// imgSlider: resize(height: 138) {
//   src
// }
// imgW640: resize(width: 640) {
//   src
// }
// imgW768: resize(width: 768) {
//   src
// }

