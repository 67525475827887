import React, { useState, memo } from "react";
import { GatsbyImage } from "gatsby-plugin-image"
import { useThemeUI } from "theme-ui";
import { alpha } from '@theme-ui/color'
// https://react-photo-album.com/documentation
import { PhotoAlbum, } from "react-photo-album";
// https://yet-another-react-lightbox.com/documentation
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { useStaticKameryPraceImages } from "../kamerove-systemy/useStaticKameryPraceImages"

const KameryPraceGallery = () => { //{ thumbnailWidth = 200 }

  const [index, setIndex] = useState(-1);
  const { theme } = useThemeUI();
  const { rawColors: colors } = theme
  const galleryImgFilesNodes = useStaticKameryPraceImages();

  const galleryImages = galleryImgFilesNodes.map((imgFileNode, i) => {

    const filename = imgFileNode.base;
    const { src, width, height } = imgFileNode.childImageSharp.original; // , aspectRatio 
    const gatsbyImageData = imgFileNode.childImageSharp.gatsbyImageData
    const thumb = imgFileNode.childImageSharp.imgGrid

    return {
      key: `${filename}-${i}`,
      src,
      // thumbnail: imgFileNode.childImageSharp.thumbH200.src,
      thumbnail: thumb.src,
      // thumbnail: imgFileNode.fixedImgW200H200.fixed.src,
      thumbnailWidth: thumb.width,
      thumbnailHeight: thumb.height,
      // thumbnailWidth: 320,
      // thumbnailHeight: 274,
      // isSelected: true,
      // srcSet,// - image srcset (html5 attribute)
      // caption: `Caption ${imgFileNode.base}`,
      // thumbnailCaption: `thumbnailCaption ${imgFileNode.base}`,
      // tags: [{ value: "foo", title: "bar" }],
      alt: imgFileNode.base,
      gatsbyImageData,
      width,
      height,
    };
  });


  const slides = galleryImages.map(({ src, width, height, gatsbyImageData, alt, key, images }) => ({
    src,
    width,
    height,
    alt,
    key,
    gatsbyImageData,
  }));

  const bgColorLightbox = `${alpha(colors.blue[8], 0.95)(theme)}`
  const bgColorThumbnailBar = `${alpha(colors.gray[8], 0.95)(theme)}`
  return (
    <div className="my-2">
      <p>
        <strong>Z našich prác:</strong>
      </p>
      <div>
        Niektoré nami namontované bezpečnostné kamery.&nbsp;
        <span role="img" aria-label="bezpečnosť">
          🛡️
        </span>
        &nbsp;Káble ku kamerám sa dajú ukryť úplne, je to vždy iba podľa
        požiadaviek zákazníka, technických podmienok, alebo cenového rozpočtu.
      </div>
      <PhotoAlbum
        photos={galleryImages}
        layout="rows"
        targetRowHeight={150}
        onClick={(event, photo, index) => setIndex(index)}
        componentsProps={{ imageProps: { loading: "lazy" } }}
        renderPhoto={
          renderingPhoto
        }
      />

      <Lightbox
        styles={{
          container: {
            backgroundColor: `${bgColorLightbox}`
          },
          thumbnailsContainer: {
            backgroundColor: `${bgColorThumbnailBar}`,
            borderWidth: 'thick',
            borderTop: 'groove',
            borderTopColor: 'crimson'
          },

          thumbnail: {
            backgroundColor: `${bgColorLightbox}`,
            borderRadius: '50%'
          },
          button: {
            backgroundColor: `${bgColorThumbnailBar}`,
            border: 'groove',
            borderColor: 'crimson',
            borderRadius: '50%',
          },
        }}
        slides={slides}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        // enable optional lightbox plugins
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
        thumbnails={{ position: "bottom", width: 120, height: 80, border: 1, borderRadius: 25, padding: 4, gap: 16, imageFit: "contain", vignette: true }}
        slideshow={{
          autoplay: false,
          delay: 3000,
        }}
        fullscreen={false}
        zoom={{
          maxZoomPixelRatio: 4,
          zoomInMultiplier: 2, doubleTapDelay: 300,
          doubleClickDelay: 500, doubleClickMaxStops: 2,
          keyboardMoveDistance: 50, wheelZoomDistanceFactor: 100,
          pinchZoomDistanceFactor: 100,
          scrollToZoom: false
        }}
        animation={{
          zoom: 1000,
        }}
        render={{
          slide: (slide, offset, rect) => {
            // console.log("KameryPraceGallery slide", slide)
            // console.log("KameryPraceGallery offset", offset) // 0
            // console.log("KameryPraceGallery rect", rect) // {height: 1149, width: 1345}

            const { alt, gatsbyImageData, key } = slide

            return (
              <div style={{
                position: "relative",
              }}
                key={key}
              >
                <GatsbyImage
                  image={gatsbyImageData}
                  imgStyle={{ borderRadius: '50px' }}
                  // src={image}
                  // layout="fill"
                  // loading="eager"
                  // placeholder="blur"
                  // objectFit="contain"
                  alt={alt} //{"alt" in image ? image.alt : ""}
                />
              </div>
            );
          }
        }}

      />

    </div>
  );
};

export default memo(KameryPraceGallery);

// layout: renderLayout,  // layoutOptions, 
const renderingPhoto = ({ photo, imageProps, wrapperStyle, }) => {
  const {
    // width, height, 
    gatsbyImageData } = photo;
  const {
    // src, 
    alt, title,
    // style: imgStyle, // {"display": "block","boxSizing": "content-box","width": "calc((100% - 120px) / 7)","height": "auto","aspectRatio": "800 / 480","cursor": "pointer"}
    // sizes, 
    className, onClick, loading } = imageProps;

  // const { 
  //   height: layoutHeight, //: 143.4857142857143
  //   index, //: 180
  //   photoIndex, //: 4
  //   photosCount, //: 7
  //   width: layoutWidth, //: 239.14285714285717
  // } = renderLayout;
  // const {
  //   layout, //: "rows",
  //   viewportWidth, //: 1873,
  //   containerWidth, //: 1794,
  //   columns, //: 5,
  //   spacing, //: 20,
  //   padding, //: 0,
  //   targetRowHeight, //: 150
  // } = layoutOptions;
  return (
    <button
      onClick={onClick}
      style={{ ...wrapperStyle, cursor: "pointer", border: 'none', padding: 0 }}
    >
      <GatsbyImage
        className={className}
        image={gatsbyImageData}
        imgStyle={{ borderRadius: '5px' }}
        // layout="fill"
        loading={loading} //"eager"
        // placeholder="blur"
        // objectFit="contain"
        alt={alt} //{"alt" in image ? image.alt : ""}
        title={title} />
    </button>
  );
};



