/** @jsx jsx */
import { Box, jsx } from "theme-ui";

const outerStyle = {
  px: [1, 2, 3],
  mx: "auto",
  mb: [1, 2],
  py: [1, 2],
};
const PrecoHik = ({ myStyle }) => (
  <Box sx={{ ...outerStyle, ...myStyle }}>
    <h4 sx={{ variant: "styles.h4" }}>Prečo Hikvision kamerové systémy</h4>

    <div className="custom">
      <p>Pre CCTV a video dohľadové zariadenia sú dôležité</p>
      <ul>
        <li>
          kvalita obrazu kamery - vrátane rozlíšenia.
          <br />
          Všetky Hikvision kamery ponúkajú kvalitný obraz. Dôležité ale je, aby
          bola zvolená kamera vhodná pre svetelné podmienky a rozmery
          monitorovaného priestoru. Niektoré kamery majú rozlíšenie až 16
          megapixelov. Vyššie rozlíšenie poskytuje kvalitnejšie detaily pri
          náhľadoch na časti obrazu - pre digitálny zoom.
        </li>
        <li>
          funkcie video analýzy a ich kvalita
          <br />
          Čím viac funkcií video analýzy, tým väčšie možnosti automatickej a
          poloautomatickej detekcie narušenia v monitorovanom priestore.
          <br />
          Ak je však nepostačujúca buď softvérová kvalita funkcií alebo kvalita
          obrazu, tak sa zvyšuje riziko falošných poplachov.
        </li>
        <li>
          efektívne ukladanie video záznamu a poskytovanie živého náhľadu
          <br />
          Vyššie rozlíšenie v obraze znamená spracovávať väčšie množstvo dát v
          obraze.
          <br />
          Hikvision má zvládnuté ukladanie videa na rekordéry s kompresiou dát
          na úrovni až H265+ a samozrejme s možnosťou spustenia a ukončenia
          nahrávania iba pre detegované narušenia.
        </li>
        <li>
          prehľadnosť a akcieschopnosť
          <br />
          Hikvision softvér Vám umožňuje rýchlo sa presúvať medzi jednotlivými
          pripojenými kamerami, od živého pohľadu k hľadaniu jednotlivých
          detekcií narušenia vo videozázname. Pri väčšej komplexnosti je výhodné
          implementovať väčšie monitory, príp. videostenu.
        </li>
      </ul>
      <br />
      <div>
        Hikvision je pre Vás správnym výberom, ak potrebujete
        <br />
        <ul>
          <li>kvalitný obraz kamery,</li>
          <li>
            aktívne využívať pokročilé video funkcie kamery pre detekcie
            narušenia s malým rizikom falošných alarmov,
          </li>
          <li>mať spoľahlivo vyriešené ukladanie videozáznamu,</li>
          <li>mať prehľad o stave Vašich kamier.</li>
        </ul>
        <p>
          <a
            href="https://www.hikvision.com/cz/products/IP-Products/"
            target="_blank"
            rel="noreferrer"
          >
            Hikvision IP kamery
          </a>
        </p>
        <p>
          <a
            href="https://www.hikvision.com/cz/newsroom/latest-news/2022/experience-new-possibilities-when-hikvision-colorvu-meet-other-technologies/"
            target="_blank"
            rel="noreferrer"
          >
            Získejte nové možnosti spojením ColorVu dalšími technologiemi
          </a>
        </p>
        <p>
          <a
            href="https://www.hikvision.com/content/hikvision/cz/newsroom/latest-news/2020/hikvision-launches-new-generation-of-acusense-products.html"
            target="_blank"
            rel="noreferrer"
          >
            Hikvision uvádí na trh novou generaci produktů AcuSense
          </a>
        </p>
        <p>
          <a
            href="https://www.hikvision.com/cz/newsroom/latest-news/2021/discover-hikvision-s-latest-colorvu-technology--even-sharper-ima/"
            target="_blank"
            rel="noreferrer"
          >
            Objevte nejnovější technologii ColorVu společnosti Hikvision: ještě ostřejší obraz s jasnějšími barvami, 24 hodin denně
          </a>
        </p>
      </div>
      <br />
      <p>
        V roku 2016 bol Hikvision opäť č.1 a s rastúcim dominantným podielom
        21,4% na celosvetovom trhu pre CCTV a video dohľadové zariadenia.
      </p>
      <p>Tiež č.1 v kategórii</p>
      <ul>
        <li>Bezpečnostné kamery 24,2%,</li>
        <li>Analógové + HD CCTV bezpečnostné kamery 23,9%,</li>
        <li>Sieťové bezpečnostné kamery 25,6%,</li>
        <li>rekordéry (25,3%)</li>
      </ul>
      <p>
        HikVision sa zaoberá výrobou všetkého, čo sa nejako dotýka video
        bezpečnostných systémov a CCTV technológie
      </p>
      <ul>
        <li>inteligentné IP kamery</li>
        <li>HD analógové kamery</li>
        <li>rýchle dome kamery</li>
        <li>rekordéry NVR a DVR</li>
        <li>softvér na management videa</li>
        <li>systémy na riadenie vstupu, vjazdu a alarmov</li>
        <li>enkódery a dekódery</li>
        <li>a iné zariadenia</li>
      </ul>
      <p>
        Hikvision reinvestuje 7% ročného príjmu do vývoja a výskumu. Za rok 2016
        vyše 400 nových patentov. Z 20 000 zamestnancov je 8000 inžinierov (2000
        softvérových inžinierov).
      </p>
      <p>Výskum</p>
      <ul>
        <li>Perceptive Technology</li>
        <li>Intelligent Analysis Technology,</li>
        <li>Big Data and Cloud Storage Technology</li>
        <li>Multimedia Technology</li>
      </ul>
      <p>
        Najväčší vývojovo výskumný tím pre umelú inteligenciu v bezpečnostnom
        priemysle.
      </p>
      <p>
        Vysoko pokročilé skúsenosti s video cielenou detekciou, segmentáciou
        obrazu, štruktúrovaním videa a zaznamenávaním videa.
      </p>
      <p>
        <a
          href="https://www.hikvision.com/cz/newsroom/latest-news/"
          target="_blank"
          rel="noreferrer"
        >
          <strong>Najnovšie články o Hikvision v češtine</strong>
        </a>
      </p>
      <hr />
      <p>
        V roku 2016 ocenený v ImageNet ako najlepší v Scene Classification (
        <a
          href="http://www.hikvision.com/en/Press-Release-details_74_i1326.html"
          target="_blank"
          rel="noreferrer"
        >
          Hikvision ranked No.1 in Scene Classification at ImageNet 2016
          challenge
        </a>
        ).
      </p>
      <p>
        <a
          href="https://www.hikvision.com/en/Press/Press-Releases/Corporate-News/305528762923783"
          target="_blank"
          rel="noreferrer"
        >
          Hikvision camera wins GIT Award 2018
        </a>
      </p>
      <p>
        <a
          href="https://www.hikvision.com/en/Press/Press-Releases/Corporate-News/305528789141006"
          target="_blank"
          rel="noreferrer"
        >
          Hikvision wins Detektor International award
        </a>
      </p>
      <p>
        <a
          href="https://www.hikvision.com/en/Press/Press-Releases/Corporate-News/Hikvision-Ranks-Number-One-on-a-s-Global-Ranking-for-Second-Consecutive-Year"
          target="_blank"
          rel="noreferrer"
        >
          Hikvision Ranks Number One on a&amp;s Global Ranking for Second
          Consecutive Year
        </a>
      </p>
      <p>
        <a
          href="https://www.hikvision.com/en/Press/Press-Releases/Corporate-News/Hikvision-Tops-IHS-Markit-List-of-Enterprise-Storage-Providers"
          target="_blank"
          rel="noreferrer"
        >
          Hikvision Tops IHS Markit List of Enterprise Storage Providers
        </a>
      </p>
      <p>
        <a
          href="https://www.hikvision.com/en/Press/Press-Releases/Corporate-News/Hikvision-Honored-with-the-Red-Dot-Award-2018"
          target="_blank"
          rel="noreferrer"
        >
          Hikvision Honored with the Red Dot Award 2018
        </a>
      </p>
      <p>
        <a
          href="https://www.hikvision.com/en/Press/Press-Releases/Corporate-News/Hikvision-Awarded-Best-Technology-Company-by-DMCC"
          target="_blank"
          rel="noreferrer"
        >
          Hikvision Awarded Best Technology Company by DMCC
        </a>
      </p>
      <p>
        <a
          href="https://www.hikvision.com/en/Press/Press-Releases/Corporate-News/Hikvision-Wins-2018-ESX-Innovation-Award-for-Video-Surveillance"
          target="_blank"
          rel="noreferrer"
        >
          Hikvision Wins 2018 ESX Innovation Award for Video Surveillance
        </a>
      </p>
      <p>
        <a
          href="https://www.hikvision.com/en/Press/Press-Releases/Corporate-News/Hikvision-wins-PSI-CCTV-Product-of-the-Year-award-for-the-third-year-running---Hikvison"
          target="_blank"
          rel="noreferrer"
        >
          Hikvision wins PSI CCTV Product of the Year award for the third year
          running
        </a>
      </p>
      <p>
        <a
          href="https://www.hikvision.com/en/Press/Press-Releases/Corporate-News/Hikvision-wins-prestigious-Benchmark-Innovation-Award"
          target="_blank"
          rel="noreferrer"
        >
          Hikvision wins prestigious Benchmark Innovation Award
        </a>
      </p>
      <p>
        <a
          href="https://www.hikvision.com/content/hikvision/en/newsroom/latest-news/2019/hikvision-to-help-launch-pioneering-secure-by-default-initiative-and-unveil-new-products-and-technologies-at-ifsec-international-2019.html"
          target="_blank"
          rel="noreferrer"
        >
          Hikvision to help launch pioneering Secure by Default initiative and
          unveil new products and technologies at IFSEC International 2019
        </a>
      </p>
      <p>
        <a
          href="https://www.hikvision.com/en/newsroom/latest-news/2019/hikvision-achieves-iso-28000--2007-supply-chain-security-managem/"
          target="_blank"
          rel="noreferrer"
        >
          Hikvision achieves ISO 28000: 2007 supply chain security management
          system certification
        </a>
      </p>
      <p>
        <a
          href="https://www.hikvision.com/en/newsroom/latest-news/2019/smart-thermal-camera-has-explosive-performance-in-git-security-a/"
          target="_blank"
          rel="noreferrer"
        >
          Smart Thermal camera has explosive performance in GIT Security Award
          2020
        </a>
      </p>
      <p>
        <a
          href="https://www.hikvision.com/en/newsroom/latest-news/2019/hikvision-wins-the-prestigious-innovative-achievement-award-at-t/"
          target="_blank"
          rel="noreferrer"
        >
          Hikvision wins the prestigious Innovative Achievement Award at the
          2019 Detektor International Awards
        </a>
      </p>
      <p>
        <a
          href="https://www.hikvision.com/content/hikvision/en/newsroom/latest-news/2019/hikvision-thermal-network-bullet-camera-wins-product-innovation-.html"
          target="_blank"
          rel="noreferrer"
        >
          Hikvision Thermal Network Bullet Camera wins Product Innovation of the
          Year at Security and Fire Excellence Awards 2019
        </a>
      </p>

      <p>
        <a
          href="https://www.hikvision.com/content/hikvision/en/newsroom/latest-news/2020/hikvision-wins-cctv-product-of-the-year-for-fifth-consecutive-ye.html"
          target="_blank"
          rel="noreferrer"
        >
          Hikvision wins CCTV Product of the Year for fifth consecutive year in PSI Premier Awards
        </a>
      </p>
      <p>
        <a
          href="https://www.hikvision.com/content/hikvision/en/newsroom/latest-news/2020/hikvision-win-security-hardware-product-innovation-of-the-year.html"
          target="_blank"
          rel="noreferrer"
        >
          Hikvision MinMoe Touch-Free Face Recognition Terminals win Security Hardware Product Innovation of the Year at Security and Fire Excellence Awards 2020
        </a>
      </p>
      <p>
        <a
          href="https://www.hikvision.com/content/hikvision/en/newsroom/latest-news/2021/security-magazine-names-hikvision-s-chuck-davis-one-of--top-cybersecurity-leaders-of-2021.html"
          target="_blank"
          rel="noreferrer"
        >
          Security Magazine Names Hikvision’s Chuck Davis one of ‘Top Cybersecurity Leaders of 2021’
        </a>
      </p>
      <p>
        <a
          href="https://www.hikvision.com/content/hikvision/en/newsroom/latest-news/2022/hikvision-wins-multiple-awards-in-user-voted-institutional-investor-awards.html"
          target="_blank"
          rel="noreferrer"
        >
          Hikvision wins multiple awards in user-voted Institutional Investor Awards
        </a>
      </p>
      <p>
        <a
          href="https://www.hikvision.com/cz/newsroom/latest-news/2022/hikvision-releases-full-year-2021-and-first-quarter-2022-financial-results/"
          target="_blank"
          rel="noreferrer"
        >
          Hikvision zveřejňuje finanční výsledky za celý rok 2021 a první čtvrtletí 2022
        </a>
      </p>
      <p>
        <a
          href="https://www.hikvision.com/cz/newsroom/latest-news/2022/hikvision-obtains-cc-eal3--certificate-for-network-cameras/"
          target="_blank"
          rel="noreferrer"
        >
          Hikvision získává certifikát CC EAL3+ pro IP kamery
        </a>
      </p>
    </div>
  </Box>
);
export default PrecoHik;
