/** @jsx jsx */
import { jsx, Card, Image, Flex, Text, Box } from "theme-ui";
import useVratnikModulyImages from "./useVratnikModulyImages";
import VratnikModulySchemaImage from "./VratnikModulySchemaImage"

const outerStyle = {
    my: ["2rem", "4rem", "6rem"],
};

const defaultStyle = {
    display: ["none", "flex"],
    mx: 0,
    p: 1,
    borderColor: "borderscolor",
    borderStyle: "ridge",
    borderWidth: "1px",
    backgroundColor: "accent",
    flexDirection: "row",
    textAlign: "center",
};

const VratnikModuly = ({ myStyle }) => {
    const vratnikModulyImages = useVratnikModulyImages();
    const arrVratnikModulyCards = vratnikModulyImages.map((imgNode, i) => (
        <Card
            sx={{
                maxWidth: 256, textAlign: "center", mx: 1
            }} key={`${imgNode.base}-${i}`}>
            <Image src={imgNode.childImageSharp.original.src} />
            <Text>
                {imgNode.name}
            </Text>
        </Card>
    ));

    return (
        <Box as="section" sx={outerStyle}>
            <h4 sx={{ mt: 1, mb: 1, variant: "styles.h4" }} >Jednotlivé moduly pre vyskladanie Hikvision dverovej stanice</h4>

            <VratnikModulySchemaImage />

            <Flex sx={{ ...defaultStyle, ...myStyle }} >
                {arrVratnikModulyCards}
            </Flex>
        </Box>
    )
}

export default VratnikModuly