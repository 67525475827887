import React from "react";
import Mesta from "../../shared/Mesta";

const styleWrap = {
    display: "contents"
}

const RightC = () => (
    <div style={styleWrap}>
        <Mesta />
    </div>
)

export default RightC;
