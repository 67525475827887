import { useStaticQuery, graphql } from "gatsby";


const useKameryMobilImages = () => {
  const data = useStaticQuery(
    graphql`
      {
        kameryMobilImages: allFile(
          filter: {
            sourceInstanceName: { eq: "dirImages" }
            relativeDirectory: { eq: "galleries/kamery-gallery-mobil" }
          }
        ) {
          nodes {
            id
            base

            childImageSharp {
              id
              original {
                src
              }

              thumb100: resize(height: 100) {
                src
              }
              imgSlider: resize(height: 175) {
                src
              }

            }
          }
        }
      }
    `
  );
  return data.kameryMobilImages.nodes;
};
export default useKameryMobilImages;
