/** @jsx jsx */
import { jsx, Container, Flex, } from "theme-ui";
import { useEffect, useState } from "react";
import { useBreakpointIndex } from '@theme-ui/match-media'
import { lazy, Suspense } from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import LayoutLMR from "../components/shared/_layoutLMR";
import { COMPONENTS_FILENAMES_IN_TEMPLATE as comps } from "../components/shared/mapComponents"
import LazyDynamicComponent from "../components/shared/LazyDynamicComponent";
import Seo from "../components/shared/Seo";
import "../css/loader.css"

// , webpackPrefetch: true 
const LazyHeaderBar = lazy(() => import(
  /* webpackMode: "lazy" */ /* webpackChunkName: 'LazyHeaderBar'*/
  "../components/shared/HeaderBar"))

const LazyFooter = lazy(() => import(
  /* webpackMode: "lazy" */ /* webpackChunkName: 'LazyFooter'*/
  "../components/shared/_footer"))

const LazyHeaderImage = lazy(() => import(
  /* webpackMode: "lazy" */ /* webpackChunkName: 'LazyHeaderImage'*/
  "../components/shared/HeaderImage"))

const styleBase = {
  flexDirection: "column",
  px: [1, 2, 3],
  mx: [1, 2, 3],
  my: [1, 2],
  py: [1, 2],
};

const heightHeaderBar = "5rem"

export const pageQuery = graphql`query ($id: String!, $contentDir: String!) {
  pageContentQueried: allFile(
    filter: {id: {eq: $id}, sourceInstanceName: {eq: "pagesMdx"}}
  ) {
    nodes {
      id
      childMdx {
        body
        frontmatter {
          sectionLMR
          sections
          sectionsLMR
          isNotMdx
          pageHeader {
            tag
            value
          }
          seoSpecialForPage: seo {
            description
            keywords
            lang
            title
          }
          ggleStructuredData {
            _type
            description
            name
            isProvider
            openingHours
            url
          }
          aggregateRating {
            _type
            ratingValue
            reviewCount
          }

        }
      }
    }
  }

  allSitePage {
    edges {
      node {
        id
      }
    }
  }
  ggleStructuredDataImages: allFile(
    filter: {sourceInstanceName: {eq: "dirStaticStructuredDataImages"}, relativeDirectory: {eq: $contentDir, ne: null}}
    ) {
      nodes {
        id
        relativePath
        base
      }
    }
  }
  `;


const PageTempl = (props) => {

  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    if (!hasMounted) setHasMounted(true);
  }, [hasMounted]);

  const indexBreakpoint = useBreakpointIndex({ defaultIndex: 0 })
  const isMobile = (hasMounted && indexBreakpoint === 0) ? true : false

  if (!hasMounted) {
    return null;
  }


  const {
    data,
    path,
    pageContext,
  } = props;

  const { contentDir = "notFound" } = pageContext
  const { pageContentQueried, ggleStructuredDataImages } = data
  const mdxNode = pageContentQueried.nodes[0].childMdx;
  const { body, frontmatter } = mdxNode;
  const { sections,
    isNotMdx,
    pageHeader,
    seoSpecialForPage,
    ggleStructuredData,
    aggregateRating } = frontmatter

  const isMobileUp = sections?.includes("MobileUp")
  const isMobileDown = sections?.includes("MobileDown")
  const isFullUp = sections?.includes("FullUp")
  const isFullDown = sections?.includes("FullDown")
  const isMainUp = sections?.includes("MainUp")
  const isMainDown = sections?.includes("MainDown")

  const isMobileUp2 = sections?.includes("MobileUp2")
  const isMobileDown2 = sections?.includes("MobileDown2")
  const isFullDown2 = sections?.includes("FullDown2")

  return (
    <div
      id="templ"
      style={{
        overflowX: "hidden",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Suspense fallback={<div className="loader" />}>
        <LazyHeaderBar heightHeaderBar={heightHeaderBar} indexBreakpoint={indexBreakpoint} />
      </Suspense>
      <Seo
        seoSpecialForPage={seoSpecialForPage}
        googleStructuredData={ggleStructuredData}
        aggregateRating={aggregateRating}
        seoImages={ggleStructuredDataImages}
        path={path}
      />
      <Suspense fallback={<div className="loader" />}>
        <LazyHeaderImage pageHeader={pageHeader} positionTop={heightHeaderBar} />
      </Suspense>

      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          mx: 0,
          position: "relative",
          flex: 1,
          pt: 0,
        }}
      >

        {isFullUp && (
          <Container sx={{ backgroundColor: "background" }} key="cont-fullUp">
            <Flex as="section" sx={styleBase} id="fullUp">
              <LazyDynamicComponent chunkName={`${contentDir}-FullUp`} relPath={`${contentDir}/${comps.FullUp}`} isQuick={true} />

            </Flex>
          </Container>
        )}

        {isMobileUp && (
          <Flex sx={styleBase} id="mobileUpI" key="mobileUp">
            <LazyDynamicComponent chunkName={`${contentDir}-MobileUp`} relPath={`${contentDir}/${comps.MobileUp}`} isQuick={true} />

          </Flex>
        )}
        {isMobileUp2 && (
          <Flex sx={styleBase} id="mobileUp" key="mobileUp2">
            <LazyDynamicComponent chunkName={`${contentDir}-MobileUp2`} relPath={`${contentDir}/${comps.MobileUp2}`} isQuick={true} />

          </Flex>
        )}

        {isMainUp && (
          <Flex sx={styleBase} id="mainUp" key="mainUp">
            <LazyDynamicComponent chunkName={`${contentDir}-MainUp`} relPath={`${contentDir}/${comps.MainUp}`} isQuick={true} />

          </Flex>
        )}

        {(!isNotMdx) && <LayoutLMR pageContext={pageContext} frontmatter={frontmatter} isMobile={isMobile} >
          {<MDXRenderer>{body}</MDXRenderer>}
        </LayoutLMR>}

        {isMainDown && (
          <Flex sx={styleBase} id="mainDown" key="mainDown">
            <LazyDynamicComponent chunkName={`${contentDir}-MainDown`} relPath={`${contentDir}/${comps.MainDown}`} isQuick={false} />

          </Flex>
        )}

        {isFullDown && (
          <Container sx={{ backgroundColor: "background" }} key="cont-fullDown">
            <Flex as="section" sx={styleBase} id={`${contentDir}-fullDown`}>
              <LazyDynamicComponent chunkName={`${contentDir}-fullDown`} relPath={`${contentDir}/${comps.FullDown}`} isQuick={false} />
            </Flex>
          </Container>
        )}


        {isFullDown2 && (
          <Container sx={{ backgroundColor: "background" }} key="cont-fullDown2">
            <Flex as="section" sx={styleBase} id="fullDown2">
              <LazyDynamicComponent chunkName={`${contentDir}-FullDown2`} relPath={`${contentDir}/${comps.FullDown2}`} isQuick={false} />

            </Flex>
          </Container>
        )}
        {
          isMobileDown && isMobile && (
            <Flex sx={styleBase} id="mobileDown" key="mobileDown" >
              <LazyDynamicComponent chunkName={`${contentDir}-MobileDown`} relPath={`${contentDir}/${comps.MobileDown}`} isQuick={false} />
            </Flex>
          )
        }

        {isMobileDown2 && isMobile && (
          <Flex sx={styleBase} id="MobileDown2" key="mobileDown2">
            <LazyDynamicComponent chunkName={`${contentDir}-MobileDown2`} relPath={`${contentDir}/${comps.MobileDown2}`} isQuick={false} />
          </Flex>
        )}
      </div>
      <Suspense fallback={<div className="loader" />}>
        <LazyFooter />
      </Suspense>
    </div>
  );
};

export default PageTempl;
