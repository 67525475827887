/** @jsx jsx */
import { jsx, Image, Box, Flex, Grid } from "theme-ui";
import imgHikSoftver from "../../../images/shared/fixed/hik-softver.png"

const headerStyle = {
    mt: 1,
    mb: 1,
    variant: "styles.h4"
};
const defaultStyle = {
    mx: 0,
    p: "1rem",
    borderColor: "borderscolor",
    borderStyle: "ridge",
    borderWidth: "1px",
    backgroundColor: "accent",
    flexDirection: "column",
};
const TerminalySoftver = ({ myStyle }) => {

    return (<Box sx={{
        my: ["2rem", "4rem", "6rem"]
    }}>
        <h4 sx={headerStyle} >Softvér</h4>
        <Grid gap={2} columns={[1, "9fr 3fr"]} sx={{ p: ["0.5rem", "1rem",] }} >
            <Box as="section" >

                <Flex sx={{ ...defaultStyle, ...myStyle }} >
                    <h4>
                        Hik-Connect (bezplatná appka v mobile)
                    </h4>
                    <ul>
                        <li>kontrola stavu, logy z prístupu, monitorovanie v reálnom čase</li>
                        <li>alarmové, varovné notifikácie</li>
                        <li>ideálny mobilný softvér pre malé firmy a zvlášť, </li>
                        <ul>
                            <li>keď má váš terminál aj videovrátnikovú funkciu</li>
                            <li>keď máte aj kamerový systém</li>
                        </ul>
                    </ul>
                    <p>
                        <a
                            href="https://www.hikvision.com/cz/products/software/hik-connect/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Hik-Connect - ľahké pripojenie z hociktorého miesta
                        </a>
                    </p>
                </Flex>
                <Flex sx={{ ...defaultStyle, ...myStyle }} >
                    <h4>
                        iVMS-4200 (bezplatný softvér v počítači) pre malé a stredné podniky
                    </h4>
                    <div>je všestranný softvér u klienta pre Hik systémy riadenia prístupu</div>
                    <div>Zahŕňa komplexné moduly pre Hikvision systémy, aj pre</div>
                    <ul style={{ marginTop: 0 }}>
                        <li>riadenie prístupu
                            <ul>
                                <li>úplné nastavenie a riadenie - užívatelia, plán prístupu</li>
                                <li>alarmové notifikácie z udalostí v reálnom čase</li>
                                <li>monitorovanie stavu dverí</li>
                                <li>zachytávanie udalostí</li>
                            </ul>
                        </li>
                        <li>dochádzkový systém (zamestnancov)
                            <ul>
                                <li>flexibilná konfigurácia dochádzkových pravidiel</li>
                                <li>časové záznamy a záznamy dochádzky</li>
                                <li>jediná databáza manažuje všetky zariadenia povoľujúce vstup</li>
                            </ul>
                        </li>
                    </ul>
                </Flex>
            </Box>
            <div sx={{ textAlign: "center" }}>
                <Image src={imgHikSoftver} alt="Hikvision manažovací softvér" />
            </div>
        </Grid>
    </Box>
    )
}

export default TerminalySoftver