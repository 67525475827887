/** @jsx jsx */
import { Grid, Box, jsx, Image } from "theme-ui";
import imgEsim320 from "../../../images/alarmy/fixed/esim-gc.jpg";

const defaultStyle = {
  px: [1, 2, 3],
  mx: "auto",
  mb: [1, 2],
  py: [1, 2],
  img: {
    variant: "images.stopercent",
  },
};

const Esim320 = ({ myStyle }) => (
  <Box as="section" id="esim320" sx={{ ...defaultStyle, ...myStyle }}>
    <h4 sx={{ variant: "styles.h4" }}>ESIM320</h4>
    <p>
      Eldes ESIM320 je gate kontroller (riadič pre brány). Má
      GSM/GPRS komunikátor, 2 relé výstupy a 3 vstupy. ESIM320 využíva 3G/2G verzie GSM.
    </p>
    <Grid gap={2} columns={["12fr", "2fr 10fr"]}>
      <Image alt="esim gc" src={imgEsim320} variant="stopercent" sx={{ display: ["none", "block"] }} />
      <Box>
        <p>
          Na diaľku - prezvonením alebo SMS z povolených mobilov alebo cez
          aplikáciu Smart Gate na mobile iOS alebo Androide - sa dá zapnúť relé
          výstup a tým aj elektrické zariadenie, ktoré je tam pripojené. Bežné
          využitia sú pre vstupné brány, garážové brány, závora na parkovisku,
          pumpa na zavlažovanie, prepínanie a monitorovanie ventilov, kúrenie,
          reštart počítačového servera, osvetlenie. ...
        </p>
        <p>Info o stave zariadenia tiež cez SMS alebo telefonát.</p>
      </Box>
    </Grid>
    <p>
      S Eldes softvérom (cez USB) sa dá nastaviť všetko, aj databáza povolených
      užívateľov (až 2000) - mobilov, pokročilý časový harmonogram. 5
      administrátorov môže nastavovať systém mobilom cez odosielané SMS alebo
      aplikáciou "Smart Gate". Cez internet - cez ELDES Cloud sa dá nastavovať
      databáza povolených užívateľov tiež aj prezerať záznamy udalostí (až
      1000).
    </p>
    <p>
      3 vstupy sa využívajú na monitorovanie aktuálneho stavu riadeného el.
      zariadenia (pre signalizáciu chybového stavu) - prepoja sa k jeho výstupom
      signalizujúcim stav. Pre stavy riadeného el. zariadenia môžte asociovať
      vaše texty. Pri chybovom stave sa odošle SMS administrátorovi. Periodické
      info SMS pre administrátora z automatických testov . Vstupy môžte ale
      využiť aj inak, napr. na pripojenie detektora dverového kontaktu.
    </p>
    <p>
      Voliteľne ho môžte mať s krytom pre inštalovanie na DIN lištu alebo s
      vodeodolným krytom IP66.
    </p>
    <p>
      Stránka výrobcu pre{" "}
      <a
        href="https://eldesalarms.com/product/esim320/"
        target="_blank"
        rel="noopener noreferrer"
      >
        ESIM320 Gate Controller
      </a>
      .
    </p>
  </Box>
);

export default Esim320;
