/** @jsx jsx */
import { Text, jsx, Box, Flex } from "theme-ui";
import {
  resetNextUuid,
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const defaultStyle = {
  marginLeft: "auto",
  marginRight: "auto",
  flexDirection: "column",
  alignItems: "flex-start",
  "& h2,h3": {
    fontSize: 1,
    fontWeight: "light",
    padding: 0,
    color: "text",
    my: 1,
  },
};


const Mesta = ({ myStyle }) => {
  resetNextUuid();
  return (
    <Flex as="section" sx={{ ...defaultStyle, ...myStyle }} id="mesta">
      <div sx={{ width: "100%" }}>

        <p as="h3">Najbližšie to k vám máme v mestách</p>
        <Box sx={{ ml: 4 }}>
          <Accordion allowMultipleExpanded={true} allowZeroExpanded={true} preExpanded={[]} >
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <h2>Skalica</h2>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Text>
                  <ul>
                    <li>
                      <h3>Brodské</h3>
                    </li>
                    <li>
                      <h3>Dubovce</h3>
                    </li>
                    <li>
                      <h3>Chropov</h3>
                    </li>
                    <li>
                      <h3>Kátov</h3>
                    </li>
                    <li>
                      <h3>Kopčany</h3>
                    </li>
                    <li>
                      <h3>Koválovec</h3>
                    </li>
                    <li>
                      <h3>Letničie</h3>
                    </li>
                    <li>
                      <h3>Lopašov</h3>
                    </li>
                    <li>
                      <h3>Mokrý Háj</h3>
                    </li>
                    <li>
                      <h3>Oreské</h3>
                    </li>
                    <li>
                      <h3>Petrova Ves</h3>
                    </li>
                    <li>
                      <h3>Popudinské Močidľany</h3>
                    </li>
                    <li>
                      <h3>Prietržka</h3>
                    </li>
                    <li>
                      <h3>Radimov</h3>
                    </li>
                    <li>
                      <h3>Radošovce</h3>
                    </li>
                    <li>
                      <h3>Trnovec</h3>
                    </li>
                    <li>
                      <h3>Unín</h3>
                    </li>
                    <li>
                      <h3>Vrádište</h3>
                    </li>
                  </ul>
                </Text>
              </AccordionItemPanel>
            </AccordionItem>
            <h2>Hodonín</h2>
            <h2>Holíč</h2>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <h2>Senica</h2>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Text>
                  <ul>
                    <li>
                      <h3>Bílkove Humence</h3>
                    </li>
                    <li>
                      <h3>Borský Mikuláš</h3>
                    </li>
                    <li>
                      <h3>Borský Svätý Jur</h3>
                    </li>
                    <li>
                      <h3>Cerová</h3>
                    </li>
                    <li>
                      <h3>Čáry</h3>
                    </li>
                    <li>
                      <h3>Častkov</h3>
                    </li>
                    <li>
                      <h3>Dojč</h3>
                    </li>
                    <li>
                      <h3>Hlboké</h3>
                    </li>
                    <li>
                      <h3>Hradište pod Vrátnom</h3>
                    </li>
                    <li>
                      <h3>Jablonica</h3>
                    </li>
                    <li>
                      <h3>Koválov</h3>
                    </li>
                    <li>
                      <h3>Kuklov</h3>
                    </li>
                    <li>
                      <h3>Kúty</h3>
                    </li>
                    <li>
                      <h3>Lakšárska Nová Ves</h3>
                    </li>
                    <li>
                      <h3>Moravský Svätý Ján</h3>
                    </li>
                    <li>
                      <h3>Osuské</h3>
                    </li>
                    <li>
                      <h3>Plavecký Peter</h3>
                    </li>
                    <li>
                      <h3>Podbranč</h3>
                    </li>
                    <li>
                      <h3>Prietrž</h3>
                    </li>
                    <li>
                      <h3>Prievaly</h3>
                    </li>
                    <li>
                      <h3>Rohov</h3>
                    </li>
                    <li>
                      <h3>Rovensko</h3>
                    </li>
                    <li>
                      <h3>Rybky</h3>
                    </li>
                    <li>
                      <h3>Sekule</h3>
                    </li>
                    <li>
                      <h3>Smolinské</h3>
                    </li>
                    <li>
                      <h3>Smrdáky</h3>
                    </li>
                    <li>
                      <h3>Sobotište</h3>
                    </li>
                    <li>
                      <h3>Šajdíkove Humence</h3>
                    </li>
                    <li>
                      <h3>Štefanov</h3>
                    </li>
                  </ul>
                </Text>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <h2>Malacky</h2>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Text>
                  <ul>
                    <li>
                      <h3>Borinka</h3>
                    </li>
                    <li>
                      <h3>Gajary</h3>
                    </li>
                    <li>
                      <h3>Jablonové</h3>
                    </li>
                    <li>
                      <h3>Jakubov</h3>
                    </li>
                    <li>
                      <h3>Kostolište</h3>
                    </li>
                    <li>
                      <h3>Kuchyňa</h3>
                    </li>
                    <li>
                      <h3>Láb</h3>
                    </li>
                    <li>
                      <h3>Lozorno</h3>
                    </li>
                    <li>
                      <h3>Jablonica</h3>
                    </li>
                    <li>
                      <h3>Malé Leváre</h3>
                    </li>
                    <li>
                      <h3>Marianka</h3>
                    </li>
                    <li>
                      <h3>Pernek</h3>
                    </li>
                    <li>
                      <h3>Plavecké Podhradie</h3>
                    </li>
                    <li>
                      <h3>Plavecký Mikuláš</h3>
                    </li>
                    <li>
                      <h3>Plavecký Štvrtok</h3>
                    </li>
                    <li>
                      <h3>Rohožník</h3>
                    </li>
                    <li>
                      <h3>Sološnica</h3>
                    </li>
                    <li>
                      <h3>Stupava</h3>
                    </li>
                    <li>
                      <h3>Studienka</h3>
                    </li>
                    <li>
                      <h3>Suchohrad</h3>
                    </li>
                    <li>
                      <h3>Veľké Leváre</h3>
                    </li>
                    <li>
                      <h3>Vysoká pri Morave</h3>
                    </li>
                    <li>
                      <h3>Záhorie</h3>
                    </li>
                    <li>
                      <h3>Záhorská Ves</h3>
                    </li>
                    <li>
                      <h3>Závod</h3>
                    </li>
                    <li>
                      <h3>Zohor</h3>
                    </li>
                  </ul>
                </Text>
              </AccordionItemPanel>
            </AccordionItem>
            <h3>Stupava</h3>
            <h2>Gbely</h2>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <h2>Myjava</h2>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Text>
                  <ul>
                    <li>
                      <h3>Brestovec</h3>
                    </li>
                    <li>
                      <h3>Bukovec</h3>
                    </li>
                    <li>
                      <h3>Chvojnica</h3>
                    </li>
                    <li>
                      <h3>Hrašné</h3>
                    </li>
                    <li>
                      <h3>Jablonka</h3>
                    </li>
                    <li>
                      <h3>Kostolné</h3>
                    </li>
                    <li>
                      <h3>Košariská</h3>
                    </li>
                    <li>
                      <h3>Krajné</h3>
                    </li>
                    <li>
                      <h3>Podkylava</h3>
                    </li>
                    <li>
                      <h3>Polianka</h3>
                    </li>
                    <li>
                      <h3>Poriadie</h3>
                    </li>
                    <li>
                      <h3>Priepasné</h3>
                    </li>
                    <li>
                      <h3>Rudník</h3>
                    </li>
                    <li>
                      <h3>Stará Myjava</h3>
                    </li>
                    <li>
                      <h3>Vrbovce</h3>
                    </li>
                  </ul>
                </Text>
              </AccordionItemPanel>
            </AccordionItem>
            <h3>Brezová pod Bradlom</h3>
            <h3>Vrbové</h3>
            <h2>Šaštín-Stráže</h2>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <h2>Pezinok</h2>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Text>
                  <ul>
                    <li>
                      <h3>Svätý Jur</h3>
                    </li>
                    <li>
                      <h3>Báhoň</h3>
                    </li>
                    <li>
                      <h3>Budmerice</h3>
                    </li>
                    <li>
                      <h3>Častá</h3>
                    </li>
                    <li>
                      <h3>Doľany</h3>
                    </li>
                    <li>
                      <h3>Dubová</h3>
                    </li>
                    <li>
                      <h3>Jablonec</h3>
                    </li>
                    <li>
                      <h3>Triblavina</h3>
                    </li>
                    <li>
                      <h3>Limbach</h3>
                    </li>
                    <li>
                      <h3>Píla</h3>
                    </li>
                    <li>
                      <h3>Slovenský Grob</h3>
                    </li>
                    <li>
                      <h3>Šenkvice</h3>
                    </li>
                    <li>
                      <h3>Štefanová</h3>
                    </li>
                    <li>
                      <h3>Viničné</h3>
                    </li>
                    <li>
                      <h3>Vinosady</h3>
                    </li>
                    <li>
                      <h3>Vištuk</h3>
                    </li>
                    <li>
                      <h3>Čierna Voda</h3>
                    </li>
                    <li>
                      <h3>Malý Raj</h3>
                    </li>
                  </ul>
                </Text>
              </AccordionItemPanel>
            </AccordionItem>
            <h3>Modra</h3>
          </Accordion>
        </Box>
      </div>
    </Flex>
  );
};

export default Mesta;
