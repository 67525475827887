import React from "react";
import { StaticImage } from 'gatsby-plugin-image';


const styleImgWrapper = {
    textAlign: "center",
};

const ImgKameroveSystemy = () => {


    return (
        <div style={{ ...styleImgWrapper }}>
            <StaticImage
                src="../../../images/kamerove-systemy/fluid/ivms-767px.jpg"
                alt="Hikvision rekordéry a kamery"
                layout="constrained"
            />
        </div>
    )
}

export default ImgKameroveSystemy

