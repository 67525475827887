/** @jsx jsx */
import { jsx, } from "theme-ui";

import { TabsR, TabListR, TabR, TabPanelR } from "../../shared/MyReactTabs"
import Montaz from "./Montaz";
import Pouzitia from "./Pouzitia";
import PrecoHik from "./PrecoHik";


const tabs = {
  "Montáž kamerových systémov": <Montaz />,
  "Použitia kamerových systémov": <Pouzitia />,
  "Prečo Hikvision": <PrecoHik />,
}

const KameryTabs = (props) => {


  return (
    <div
      id="kameryTabs"
      style={{ display: "block" }}
    >


      <TabsR forceRenderTabPanel defaultIndex={1}>
        <TabListR id="list">
          {Object.keys(tabs).map(key => (
            <TabR key={`tab_${key}`} >{key}</TabR>
          ))}
        </TabListR>
        {Object.keys(tabs).map(key => (
          <TabPanelR key={`panel_${key}`} isNavigation >{tabs[key]}</TabPanelR>
        ))}
      </TabsR>
    </div>
  )
};

export default KameryTabs;
