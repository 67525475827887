/** @jsx jsx */

import { Grid, Box, jsx, Image } from "theme-ui";
import imgEsim252 from "../../../images/alarmy/fixed/esim-gd.jpg";


const defaultStyle = {
  px: [1, 2, 3],
  mx: "auto",
  mb: [1, 2],
  py: [1, 2],
  img: {
    variant: "images.stopercent",
  },
};

const Esim252 = ({ myStyle }) => (
  <Box as="section" id="esim252" sx={{ ...defaultStyle, ...myStyle }}>
    <h4 sx={{ variant: "styles.h4" }}>ESIM252</h4>
    <p>Eldes ESIM252 je GSM komunikátor. Má 5 vstupov a 2 relé výstupy.</p>
    <p>
      Eldes ESIM252 slúži najmä na oznamovanie poplachov lokálneho
      zabezpečovacieho systému, ale aj na riadenie elektrických zariadení.
    </p>
    <p>Vyžaduje vloženie SIM karty.</p>
    <p>
      Monitoruje 5 vstupov - zvyčajne sa pripoja detektor dymu, detektor vody a
      pod., (ale je možné pripojiť napr. aj EZS ústredňu cez jej programovateľný
      výstup)
    </p>
    <Grid gap={2} columns={["12fr", "2fr 10fr"]}>
      <Image alt="esim 252" src={imgEsim252} variant="stopercent" sx={{ display: ["none", "block"] }} />
      <Box>
        Dáva oznamy o zmene stavu na vstupe volaním a s SMS správami 5
        administrátorom na mobil a na PCO (pult centrálnej ochrany). Pre oznamy
        o každej aktivácii a obnove stavu vstupu je možné nahrať až 10 hlasových
        správ a prispôsobiť SMS texty. Záznam až 500 udalostí. Periodické
        infoSMS o stave systému. Jednotlivý vstup je vyhodnotený ako aktivovaný,
        ak sa prekročí jeho nastavená citlivosť - minimálne trvanie stavu; alebo
        pri dosiahnutí nastaveného počtu impulzov bez obmedzenia časových
        intervalov medzi impulzami (pri zapnutej funkcii počítadla impulzov).
        Konfigurovanie lokálne cez miniUSB s Eldes softvérom až do detailnej
        úrovne oznamovania pre jednotlivý vstup a pre jednotlivého
        administrátora. Konfigurovanie mobilom pre 5 administrátorov cez SMS
        príkazy.
      </Box>
    </Grid>
    <p>Ak k nemu pripojíte mikrofón, dá sa komunikátor odpočúvať na diaľku.</p>
    <p>
      5 administrátorov a PCO môžu na diaľku riadiť relé výstupy - tu pripojené
      elektrické zariadenia zapínať/vypínať - napr. vstupné brány, garážové
      brány, závory na parkovisku, pumpy na zavlažovanie, prepínanie a
      monitorovanie ventilov, kúrenie,osvetlenie, reštart PC, routera, aktivovať
      kontrollery alebo napr. zapínať/vypínať zabezpečovací systém bez vlastného
      GSM modulu.
    </p>
    <p>
      Ovládanie výstupov je prezvonením alebo cez SMS alebo podľa časového
      harmonogramu. Možné potvrdenia od systému o zmene stavu výstupu cez SMS s
      uvedením názvu výstupu a prezvonením.
    </p>
    <p>
      Stránka výrobcu pre{" "}
      <a
        href="https://eldesalarms.com/product/esim252/"
        target="_blank"
        rel="noopener noreferrer"
      >
        GSM komunikátor ESIM252
      </a>
      .
    </p>
  </Box>
);

export default Esim252;
