/** @jsx jsx */
import { jsx } from "theme-ui";
import Mesta from "../../shared/Mesta";
import LazyWeb7skHtml5Banner from "../../shared/LazyWeb7skHtml5Banner";

const styleWrap = {
  display: ["none", "contents"],
}

const RightC = ({ children, myStyle }) => (
  <div style={styleWrap}>
    <Mesta />
    <LazyWeb7skHtml5Banner />
  </div>
);

export default RightC;

