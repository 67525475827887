/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import VratnikModuly from "./VratnikModuly"
import VratnikSoftver from "./VratnikSoftver"
import VratnikVnutornaStanica from "./VratnikVnutornaStanica"
import VratnikDverovaStanica from "./VratnikDverovaStanica"
import VratnikVyzvaKObj from "./VratnikVyzvaKObj"
import VideoVratniky from "./VideoVratniky";

const outerStyle = {
  mx: [1, 2, 3, 4],
};
const FullDown = () => (
  <Box as="section" sx={outerStyle}>
    <VratnikVnutornaStanica />
    <VratnikDverovaStanica />
    <VratnikModuly />
    <VratnikSoftver />
    <VratnikVyzvaKObj />
    <VideoVratniky />
    <p>
      <a
        href="https://www.hikvision.com/cz/products/Video-Intercom-Products/"
        target="_blank"
        rel="noreferrer"
      >
        Hikvision videovrátniky (interkomy)
      </a>
    </p>
    <p>
      <a
        href="https://www.hikvision.com/cz/newsroom/latest-news/2021/hikvision-announces-technology-partnership-with-yeastar/"
        target="_blank"
        rel="noreferrer"
      >
        Hikvision oznamuje technologické partnerství se společností Yeastar pro integraci video interkomu na bázi IP
      </a>
    </p>
    <p>
      <a
        href="https://www.hikvision.com/cz/newsroom/latest-news/2021/introduces-all-in-one-indoor-station-for-convergent-security-solutions/"
        target="_blank"
        rel="noreferrer"
      >
        Vnútorná stanica All-in-one pre konvergentné bezpečnostné riešenie - úvod
      </a>
    </p>
    <p>
      <a
        href="https://www.hikvision.com/cz/products/Video-Intercom-Products/IP-Series/all-in-one-indoor-station/"
        target="_blank"
        rel="noreferrer"
      >
        Vnútorná stanica All-in-one pre konvergentné bezpečnostné riešenie
      </a>
    </p>

  </Box>
);

export default FullDown;
