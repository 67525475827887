/** @jsx jsx */
import { jsx, Image, Box, Flex, Grid } from "theme-ui";
import imgHikSoftver from "../../../images/shared/fixed/hik-softver.png"


const headerStyle = {
    my: 1,
    variant: "styles.h4"
};
const defaultStyle = {
    mx: 0,
    p: "1rem",
    borderColor: "borderscolor",
    borderStyle: "ridge",
    borderWidth: "1px",
    backgroundColor: "accent",
    flexDirection: "column",
};


const VratnikSoftver = ({ myStyle }) => {

    return (
        <Box sx={{ my: ["2rem", "4rem", "6rem"] }}>
            <h4 sx={headerStyle} >Softvér</h4>
            <Grid gap={2} columns={[1, "3fr 9fr"]} sx={{ p: ["0.5rem", "1rem",] }} >
                <div sx={{ textAlign: "center" }}>
                    <Image src={imgHikSoftver} alt="Hikvision manažovací softvér" />
                </div>
                <Box as="section" >

                    <Flex sx={{ ...defaultStyle, ...myStyle }} >
                        <h4>
                            Hik-Connect (bezplatná appka v mobile)
                        </h4>
                        <ul>
                            <li>kedykoľvek si môžete cez mobil pozrieť live prenos videa z kamery v dverovej stanici a komunikovať s tým, kto vám práve u dverí zazvonil. - Vhodné ak sa práve nenachádzate doma.</li>
                            <li>ovládanie vašich dverí na diaľku</li>
                            <li>kontrola a prehrávanie zaznamenaného videa</li>
                            <li>možné zdieľanie účtu (nastavenia) s členmi rodiny</li>
                        </ul>
                        <p>
                            <a
                                href="https://www.hikvision.com/cz/products/software/hik-connect/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Hik-Connect - ľahké pripojenie z hociktorého miesta
                            </a>
                        </p>
                    </Flex>

                    <Flex sx={{ ...defaultStyle, ...myStyle }} >
                        <h4>
                            iVMS-4200 (bezplatný softvér v počítači)
                        </h4>
                        <ul>
                            <li>riadenie všetkých zariadení (nielen videovrátnik, aj kamerový systém, systém riadenia prístupu, alarmový systém) s jediným softvérom</li>
                            <li>modulový systém umožňuje výber iba tých funkcií, ktoré potrebujete</li>
                            <li>k dispozícii moduly pre kamerový systém, pre videovrátnik, pre riadenie povolení k vstupu (Access Control), a pre detekciu nepovoleného vniknutia (Intrusion Detection) modul alarmových notifikácií pre okamžité monitorovanie</li>
                            <li>jediná databáza manažuje všetky zariadenia povoľujúce vstup</li>
                        </ul>
                    </Flex>
                </Box>
            </Grid>
        </Box>
    )
}

export default VratnikSoftver