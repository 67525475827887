/** @jsx jsx */
import { useState } from "react";
import { jsx, Box, Image } from "theme-ui";

import VerticallyCenteredModal from "./VerticallyCenteredModal";
import imgLicencia from "../../images/shared/fixed/lic-nova-w200.jpg";
import imgLicenciaBig from "../../images/shared/fixed/lic-nova-w600.jpg";

const styleImgBig = {
  width: "100%",
  maxWidth: "600px",
  maxHeight: "100%",
};

const Licencia = (props) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <Box>
      <button
        sx={{ textAlign: "center", border: "unset" }}
        onClick={() => setModalShow(true)}
        {...props}
      >
        <Image
          alt="licencia"
          src={imgLicencia}
          sx={{ width: "100%", maxWidth: "min-content", cursor: "pointer" }}
          loading="lazy"
        />
      </button>
      <VerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        title="Licencia na prevádzkovanie technickej služby"
      >
        <Image
          sx={styleImgBig}
          src={imgLicenciaBig}
          title="licencia na technickú službu"
          alt="licencia na technickú službu"
        />
      </VerticallyCenteredModal>
    </Box>
  );
};

export default Licencia;

