/** @jsx jsx */
import { jsx, Box, } from "theme-ui";
import { TabsR, TabListR, TabR, TabPanelR } from "../../shared/MyReactTabs"
import Esim384 from "./Esim384";
import Esim252 from "./Esim252";
import Esim320 from "./Esim320";
import EldesPitbull from "./EldesPitbull";

const tabs = {
  "ESIM 384": <Esim384 />,
  "Pitbull Alarm": <EldesPitbull />,
  "ESIM 320": <Esim320 />,
  "ESIM 252": <Esim252 />,
}

const AlarmProductsTabs = (props) => {
  return (
    <Box>

      <TabsR forceRenderTabPanel >
        <TabListR >
          {Object.keys(tabs).map(key => (
            <TabR key={`tab_${key}`} >{key}</TabR>
          ))}
        </TabListR>
        {Object.keys(tabs).map(key => (
          <TabPanelR key={`panel_${key}`} isNavigation >{tabs[key]}</TabPanelR>
        ))}
      </TabsR>
    </Box>
  )
};

export default AlarmProductsTabs;
