/** @jsx jsx */
import { Image, jsx } from "theme-ui";
import imgSwitch from "../../images/shared/fixed/switch.jpg";

const defaultStyle = {
  display: ["none", "block", null, null],
  p: [null, 0, 1, 2],
  m: [null, 0, 1, 2],
};


const ImgColSwitch = ({ myStyle }) => (
  <Image sx={{ ...defaultStyle, ...myStyle }} alt="switch" src={imgSwitch} />
);

export default ImgColSwitch;
