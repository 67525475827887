/** @jsx jsx */
import { jsx, css } from "theme-ui";
import Licencia from "../../shared/Licencia";
import ImgColKamera from "../../shared/ImgColKamera";
import Mesta from "../../shared/Mesta";
import LogoLinkHome from "../../shared/LogoLinkHome";
import KontaktInfo from "../../shared/KontaktInfo";

const styleWrap = {
  display: "contents"
}

const LeftC = () => (
  <div style={styleWrap}>
    <LogoLinkHome />
    <KontaktInfo />
    <ImgColKamera />
    <div
      css={css`
        padding: 2rem;
      `}
    >
      <Licencia />
    </div>
    <Mesta />
  </div>
);

export default LeftC;
