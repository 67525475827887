/** @jsx jsx */
import { jsx, Flex, Box } from "theme-ui";

const outerStyle = { my: [1, 2, 3, 4] };
const defaultStyle = {
  mx: 0,
  p: [1, 2, 3, 4],
  borderColor: "borderscolor",
  borderStyle: "ridge",
  borderWidth: "1px",
  backgroundColor: "accent",
  flexDirection: "column",
  textAlign: "center",
};

const PripojenieCezInternet = ({ myStyle }) => (
  <Box sx={outerStyle}>
    <Flex as="section" sx={{ ...defaultStyle, ...myStyle }}>
      <div>
        <span role="img" aria-label="info vzdialene pripojenie">
          ℹ️
        </span>
        &nbsp; V prípade vzdialeného prístupu cez internet ku kamerovému systému
        Hikvision,
      </div>
      <div>
        nie je nutné si u vášho internetového poskytovateľ služieb objednávať
        verejnú statickú /pevnú/ IP adresu:
      </div>
      <div>
        Z vašich mobilov, tabletov a počítačov je možné sa ku kamerám pripájať
        cez internet
      </div>
      <div>bez ďalších mesačných poplatkov.</div>
    </Flex>
  </Box>
);

export default PripojenieCezInternet;
