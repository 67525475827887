/** @jsx jsx */
import { jsx, Box } from "theme-ui";


const outerStyle = {
  my: [1, 2, 3, 4],
  textAlign: "center",
};

const MapaSidlo = () => (
  <Box sx={outerStyle}>
    <iframe
      title="Adresa pc123.sk"
      width="650"
      height="450"
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d375163.80932390154!2d16.99911022404066!3d48.54410938161618!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5c61d71fa3995b58!2spc123+-+Kamerov%C3%A9+a+alarmov%C3%A9+syst%C3%A9my!5e0!3m2!1ssk!2ssk!4v1565708984644!5m2!1ssk!2ssk"
      frameBorder="0"
      allowFullScreen="allowfullscreen"
    />
  </Box>
);
export default MapaSidlo;
