/** @jsx jsx */
import { Box, jsx } from "theme-ui";

const outerStyle = {
  width: "100%",
  borderColor: "borderscolor",
  color: "text",
  borderWidth: "1px",
  borderStyle: "ridge",
  padding: [1, 2, 3, 4],
  mx: "auto",
  my: [1, 2, 3, 4],
  p: [1, 2, 3, 4],
  textAlign: "center",
};

const PonukaSluzieb = () => (
  <Box as="section" id="ponuka-sluzieb" sx={outerStyle}>
    <p>
      Môžeme vám nainštalovať nielen nový kamerový systém, ale aj rozšíriť váš
      existujúci.
    </p>
    <div>
      A nemusíte ho mať poskladaný z Hikvision kamier a rekordéru. A nevadí ani
      ak máte analógové kamery. Budete ich môcť využívať naďalej.
    </div>
    <p>
      Predložíme vám cenovo efektívne riešenie kamerového systému namerané
      presne pre váš prípad - presne na to, čo od neho konkrétne požadujete.
    </p>
    <p>
      Upozorníme vás na možné rozšírenia alebo obmedzenia. Hikvision má
      modulárny systém. Dá sa začať aj iba s jednoduchým. Jedna kamera alebo
      viac + jeden rekordér.
    </p>
  </Box>
);

export default PonukaSluzieb;
