/** @jsx jsx */
import { Box, jsx } from "theme-ui";
import imgKamera from "../../images/shared/fixed/kamera.jpg";

const defaultStyle = {
  display: ["none", "block", null, null],
  py: [1, 2],
  my: [1, 2],
};

const ImgColKamera = ({ myStyle }) => (
  <Box sx={{ ...defaultStyle, ...myStyle }}>
    <img alt="kamera" src={imgKamera} style={{ width: "100%" }} loading="lazy" />
  </Box>
);

export default ImgColKamera;
