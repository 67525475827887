import React from "react";
import Dolezite from "../../shared/Dolezite";
import LazyWeb7skHtml5Banner from "../../shared/LazyWeb7skHtml5Banner";


const FullDown = () => (
  <div>
    <Dolezite />
    <LazyWeb7skHtml5Banner isSideCol={false} />
  </div>
);

export default FullDown;
