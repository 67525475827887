import React from "react";
import Mesta from "../../shared/Mesta";

const FullDown2 = () => (
  <div style={{ display: "flex", flexDirection: "column" }}>
    <div style={{ textAlign: "center" }} >
      <Mesta />
    </div>
  </div>
);

export default FullDown2;
