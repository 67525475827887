/** @jsx jsx */
import { jsx, Flex, Box } from "theme-ui";

const outerStyle = { my: [1, 2, 3, 4] };
const defaultStyle = {
  m: 0,
  span: { color: "primary" },
};

const PripravteSi = ({ myStyle }) => (
  <Box sx={outerStyle}>
    <span role="img" aria-label="info pripravte si">
      ℹ️
    </span>

    <Flex as="section" sx={{ ...defaultStyle, ...myStyle }}>
      <div>
        <span>
          V prípade, ak si prajete stanoviť hrubú orientačnú cenu kamerového
          systému bez predchádzajúcej obhliadky, potrebujeme okrem iného vedieť:
        </span>
        <p />
        <ul>
          <li>
            <span role="img" aria-label="tick">
              ✅
            </span>
            &nbsp;či si prajete analógový, alebo IP kamerový systém
          </li>
          <li>
            <span role="img" aria-label="tick">
              ✅
            </span>
            &nbsp;počet kamier
          </li>
          <li>
            <span role="img" aria-label="tick">
              ✅
            </span>
            &nbsp;rozlíšenie kamier v MPx
          </li>
          <li>
            <span role="img" aria-label="tick">
              ✅
            </span>
            &nbsp;dĺžku nočného prísvitu
          </li>
          <li>
            <span role="img" aria-label="tick">
              ✅
            </span>
            &nbsp;spôsob akým si prajete napájať kamery: rekordér s POE
            napájaním, alebo bez
          </li>
          <li>
            <span role="img" aria-label="tick">
              ✅
            </span>
            &nbsp;ideálne, ak viete velkosť objektívu jednotlivých kamier,
            prípadne uveďte aspoň horizontálny uhol pohľadu
          </li>
        </ul>
      </div>
    </Flex>
  </Box>
);

export default PripravteSi;
