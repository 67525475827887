import { useStaticQuery, graphql } from "gatsby";

const useVratnikModulyImages = () => {
  const data = useStaticQuery(
    graphql`
      {
        vratnikModulyImages: allFile(
          filter: {
            sourceInstanceName: { eq: "dirImages" }
            relativeDirectory: { eq: "galleries/videovratnik-moduly" }
          }
        ) {
          nodes {
            id
            base
            name
            childImageSharp {
              id
              original {
                src
              }
            }
          }
        }
      }
    `
  );
  return data.vratnikModulyImages.nodes;
};
export default useVratnikModulyImages;
