import React from "react";
import { Box } from "theme-ui";
import Dolezite from "../../shared/Dolezite";


const FullUp = () => (
  <Box>
    <Dolezite />
  </Box>
);

export default FullUp;

