/** @jsx jsx */
import Modal from "react-modal"; // http://reactcommunity.org/react-modal/
import { Button, Box, jsx } from "theme-ui";

const styleModalOverlay = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(255, 255, 255, 0.75)",
};

const styleModalContent = {
  position: "absolute",
  top: "50%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  border: "1px solid #ccc",
  background: "#fff",
  overflow: "auto",
  WebkitOverflowScrolling: "touch",
  borderRadius: "4px",
  outline: "none",
  padding: "20px",
};


Modal.defaultStyles.overlay.backgroundColor = "cornsilk";
Modal.setAppElement("#___gatsby");

const VerticallyCenteredModal = ({
  show,
  onHide,
  children,
  title,
  ...rest
}) => {

  function closeModal() {
    onHide();
  }

  return (
    <Modal
      {...rest}
      isOpen={show}
      onRequestClose={closeModal}
      style={{
        overlay: styleModalOverlay,

        content: styleModalContent,
      }}
      contentLabel="Example Modal"
      className="Modal"
      overlayClassName="Overlay"
      aria={{
        labelledby: "heading",
        describedby: "full_description",
      }}
    >
      <Box sx={{ maxHeight: "80vh" }}>
        {children}
        <p>{title}</p>
        <Button onClick={closeModal}>Zatvoriť</Button>
      </Box>
    </Modal>
  );
};

export default VerticallyCenteredModal;
