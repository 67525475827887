/** @jsx jsx */
import { jsx, Flex } from "theme-ui";
import { Link } from "gatsby";


const outerStyle = {
  display: ["none", "flex"],
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "left",
  backgroundColor: "headerback",
  borderRadius: "10px",
  a: {
    variant: "links.styledLink",
    whiteSpace: "pre-wrap",
    my: 1,
    px: "1rem",
    fontSize: [null, 1, 2, 3]
  },
};



const NavLeft = () => (
  <Flex sx={outerStyle} as="nav">
    <Link
      activeClassName="active"
      sx={{ variant: "links.styledHeaderLink" }}
      to="/referencie/"
    >
      Z našich prác
    </Link>
  </Flex>
);

export default NavLeft;
