/** @jsx jsx */
import { jsx } from "theme-ui";
import LogoLinkHome from "../../shared/LogoLinkHome";
import KontaktInfo from "../../shared/KontaktInfo";
import Mesta from "../../shared/Mesta";
import LazyWeb7skHtml5Banner from "../../shared/LazyWeb7skHtml5Banner";

const styleWrapMesta = {
    display: ["none", "block", null, null],
    p: [null, 0, 1, 2],
    m: [null, 0, 1, 2],
};

const styleWrap = {
    display: "contents"
}

const LeftC = ({ myStyle }) => (
    <div style={styleWrap}>
        <LogoLinkHome />
        <KontaktInfo />
        <div sx={{ ...styleWrapMesta, ...myStyle }} >
            <Mesta />
        </div>
        <LazyWeb7skHtml5Banner />
    </div>

);

export default LeftC;
