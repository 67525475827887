/** @jsx jsx */
import { jsx, Flex, Box } from "theme-ui";

const outerStyle = { my: [1, 2, 3, 4] };
const defaultStyle = {
  mx: 0,
  p: [1, 2, 3, 4],
  borderColor: "primary",
  borderStyle: "ridge",
  borderWidth: "1px",
};

const Obhliadka = ({ myStyle }) => (
  <Box sx={outerStyle}>
    <span role="img" aria-label="info obhliadka">
      ℹ️
    </span>
    <Flex as="section" sx={{ ...defaultStyle, ...myStyle }}>
      <p>
        Obhliadka vášho objektu, výber a návrh najvhodnejšieho technického
        riešenia kamerového, alebo poplachového systému sú vykonávané bez poplatku.
      </p>
    </Flex>
  </Box>
);

export default Obhliadka;
