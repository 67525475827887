/** @jsx jsx */
// import {Fragment} from "react";

import { jsx, Image } from "theme-ui";
import imgFaceTerminalValue from "../../../images/terminaly/face-value.png"
import Mesta from "../../shared/Mesta";
import LazyWeb7skHtml5Banner from "../../shared/LazyWeb7skHtml5Banner";


const styleWrap = {
  display: "contents"
}

const RightC = ({ children, myStyle }) => (
  <div style={styleWrap}>
    <Image src={imgFaceTerminalValue} alt="Face Terminal Value" />
    <Mesta />
    <LazyWeb7skHtml5Banner />
  </div>
);

export default RightC;
