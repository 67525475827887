/** @jsx jsx */
import { Fragment } from "react";
import { Box, jsx } from "theme-ui";
import Video from "../../shared/Video";


const outerStyle = {
  px: [1, 2, 3],
  mx: "auto",
  mb: [1, 2],
  py: [1, 2],

  h5: { fontSize: 2 },
};



const hikData = [
  {
    header: "Monitorovanie väčšieho otvoreného územia a zároveň aj sledovanie detailu - jednou kamerou",
    uses: [
      "perimeter", "verejné námestia", "dopravné križovatky", "cesty", "parkoviská", "sklady"
    ],
    articles: [
      {
        link: "https://www.hikvision.com/cz/newsroom/latest-news/2022/four-ways-to-power-security-in-large-sites-and-open-areas/",
        title: "4 spôsoby, ako zvýšiť zabezpečenie vo veľkých lokalitách a otvorených oblastiach"
      },
      {
        link: "https://www.hikvision.com/cz/newsroom/latest-news/2022/hikvision-launches-new-deepinview-bullet-cameras-with-tandemvu-technology/",
        title: "Hikvision uvádza na trh nové bullet kamery DeepinView s technológiou TandemVu"
      },
      {
        link: "https://www.hikvision.com/cz/newsroom/latest-news/2022/hikvision-reveals-tandemvu-ptz-cameras-with-ptz-and-bullet-camer/",
        title: "Hikvision predstavuje kamery PTZ TandemVu s funkciami PTZ a bullet-kamery v jednej jednotke"
      },
      {
        link: "https://www.hikvision.com/cz/newsroom/latest-news/2022/hikvision-releases-panoramic-camera-range-with-colorvu-for-wide-field-of-view/",
        title: "Hikvision uvádza na trh panoramatické kamery s ColorVu pre široké zorné pole v živých farbách"
      }, {
        link: "https://www.hikvision.com/cz/products/IP-Products/PTZ-Cameras/tandemvu-ptz-cameras/?category=S%C3%AD%C5%A5ov%C3%A9+produkty&subCategory=PTZ+Cameras&series=Kamery+PTZ+TandemVu&checkedSubSeries=NONE",
        title: "PTZ TandemVu"
      }, {
        link: "https://www.hikvision.com/cz/products/IP-Products/PTZ-Cameras/",
        title: "PTZ kamery"
      },
    ],
    videos: [
      {
        link: "https://www.youtube.com/embed/zN2BDRzyGyE",
        title: "Intelligent Applications of Hikvision’s TandemVu PTZ Cameras"
      },
      {
        link: "https://www.youtube.com/embed/v8_7dW_JaE0",
        title: "Meet Hikvision's Next Generation PTZ Cameras"
      },
      {
        link: "https://www.youtube.com/embed/eWIhB0xL6jY",
        title: "Hikvision PTZ Cameras – Highlights and Key Features"
      },
    ],
  },
  {
    header: "Monitorovanie otvoreného územia z diaľky",
    uses: [
      "Ideálna pre dopravné uzly, ako sú letiská a železničné stanice, dialnice a štadióny, ako aj pre priemyslové zariadenia ako oceliarne a chemické závody",
      "Jedna kamera dokáže pokryť veľké priestory",
      "Spoľahlivá vonkajšia prevádzka za nepriaznivého počasia",
      "Kvalitné záznamy možno stále zachytiť zo vzdialenejšieho, ale bezpečného prevádzkového prostredia (ak nie je možné osadiť kameru v blízkosti sledovaného miesta)",
      "Mimoriadne dlhá ohnisková vzdialenosť v spojení s rozlíšením 4MP umožňuje zachytenie detailov. Kamera, ktorá využíva algoritmy Deep Learning, je schopná vyhovieť pokročilejším bezpečnostným potrebám, vrátane ochrany perimetra, rozpoznávania tváre a rozpoznávania viac cieľov u ľudí a vozidla.",
    ],
    articles: [
      {
        link: "https://www.hikvision.com/cz/newsroom/latest-news/2021/hikvision-adds-network-bullet-cameras-with-25x-optical-zoom-to-i/",
        title: "Hikvision DeepinView sieťové bullet kamery majú 25x optický zoom"
      },
    ],

  },
  {
    header: "Ochrana vonkajšieho priestoru",
    uses: [
      "perimeter, obvod otvorenej plochy, územia",
    ],
    articles: [
      {
        link: "https://www.hikvision.com/cz/solutions/solutions-by-function/perimeter-protection/",
        title: "Prvá obranná línia"
      },
      {
        link: "https://www.hikvision.com/cz/products/Thermal-Products/Security-thermal-cameras/",
        title: "Ochrana perimetra za každého počasia"
      },
      {
        link: "https://www.hikvision.com/content/hikvision/cz/newsroom/latest-news/2020/enhanced-perimeter-protection-with-hikvision-thermal-products.html",
        title: "Enhanced perimeter protection with Hikvision thermal products"
      },
      {
        link: "https://www.hikvision.com/cz/newsroom/latest-news/2021/discover-hikvision-s-latest-colorvu-technology--even-sharper-ima/",
        title: "Hikvision technológia ColorVu - ešte ostrejší obraz s jasnejšími farbami, 24 hodín denne"
      },
    ],
  },
  {
    header: "Ochrana pred vypuknutím požiaru",
    uses: [
      "odpadové skládky", "sklady",
    ],
    articles: [
      {
        link: "https://www.hikvision.com/cz/solutions/solutions-by-scenario/recycling",
        title: "Riešenie pre recykláciu. 24/7 presné meranie teploty pre protipožiarnu ochranu"
      },
      {
        link: "https://www.hikvision.com/content/hikvision/cz/newsroom/latest-news/2021/heatpro-series-brings-accurate-perimeter-defense-and-fire-detection-to-mass-market.html",
        title: "Kamery HeatPro prinášajú na trh presnú obranu obvodu územia a detekciu požiaru"
      },
      {
        link: "https://www.hikvision.com/cz/products/Thermal-Products/Security-thermal-cameras/heatpro-series/",
        title: "Termokamery HeatPro"
      },
      {
        link: "https://www.hikvision.com/content/hikvision/cz/newsroom/latest-news/2021/waste-recycling-article-with-thermal-for-temperature-measurement.html",
        title: "Zachytenie požiaru odpadu ešte na začiatku"
      },
      {
        link: "https://www.hikvision.com/cz/products/Thermal-Products/",
        title: "Hikvision termokamery"
      },
      {
        link: "https://www.hikvision.com/content/hikvision/cz/newsroom/latest-news/2020/hikvision-obtains-cnpp-certified-certificates--for-its-thermal-s.html",
        title: "Hikvision získal CNPP certifikát za bezpečnostné termokamery"
      },
    ],
    videos: [
      {
        link: "https://www.youtube.com/embed/videoseries?list=PLgvgXxvr9r9D-g8G0OHHWCR5-yhK7iM-s",
        title: "Hikvision Thermal Camera Playlist"
      },
    ],
  },
  {
    header: "Kombinácia spúšťania zvukových výstrah pri detekcii kamerou + možnosť okamžitej komunikácie",
    articles: [
      {
        link: "https://www.hikvision.com/cz/solutions/solutions-by-function/video-and-audio-convergence-solution/",
        title: "CCTV + audio technológie + komunikácia"
      },
      {
        link: "https://www.hikvision.com/cz/products/Alarm-Products/Emergency-Alarm/",
        title: "Rýchla reakcia na potreby zabezpečenia"
      },
    ],
    videos: [
      {
        link: "https://www.youtube.com/embed/Q_p3pka81uc",
        title: "Network Cameras with ColorVu and Live Guard"
      },
    ],
  },
  {
    header: "Identifikácia ŠPZ áut",
    articles: [
      {
        link: "https://www.hikvision.com/cz/solutions/solutions-by-function/vehicle-information-detection/",
        title: "Detekcia vozidla"
      },
    ],
    videos: [
      {
        link: "https://www.youtube.com/embed/6l2_oNwNYJ8",
        title: "Hikvision Smart Monitoring Camera | ANPR with 24/7 sharper images"
      },
    ],
  },
  {
    header: "Monitorovanie hustoty ľudí v priestore, Počítanie osôb. Zisťovanie trasy pohybujúcich sa osôb.",
    uses: [
      "napr. zákazníkov v obchode",
      "u pokladní a pod.",
      "pre riadenie prevádzky",
      "optimalizácia vystaveného tovaru",
      "sociálne vzdialenosti",
    ],
    articles: [
      {
        link: "https://www.hikvision.com/cz/solutions/solutions-by-function/heat-mapping/",
        title: "Teplotné mapy"
      },
      {
        link: "https://www.hikvision.com/cz/solutions/solutions-by-function/people-counting/",
        title: "Dobré čísla znamenajú dobrý obchod"
      },
      {
        link: "https://www.hikvision.com/cz/solutions/solutions-by-function/heat-mapping/",
        title: "Optimalizácia rozmiestnenia tovaru a maximalizácia výnosov"
      },
    ],

  },
  {
    header: "Videovrátniky",
    uses: [
      "domy", "bytové domy", "budovy s vrátnicou, recepciou",
      "monitorovanie a zaznamenávanie situácie vo vnútorných priestoroch a pred vstupnými dverami, príp. na dvore alebo v garáži",
      "autorizované osoby majú vstup umožnený cez heslo, kartu, kľúčenku, ...",
      "povoľovanie vstupu pre ľudí neautorizovaných na vstup od vnútornej stanice (tabletu) prostredníctvom video komunikácie",
    ],
    videos: [
      {
        link: "https://www.youtube.com/embed/videoseries?list=PLgvgXxvr9r9BaC_mypC3L7MPkLFZ3RtkE",
        title: "Video Intercom"
      },
    ],
  },
  {
    header: "Riadenie vstupu osôb",
    uses: [
      "dochádzkové systémy",
    ],
    articles: [
      {
        link: "https://www.hikvision.com/cz/solutions/solutions-by-function/access-control/",
        title: "Riadenie prístupu"
      },
      {
        link: "https://www.hikvision.com/content/hikvision/cz/newsroom/latest-news/2020/hikvision-win-security-hardware-product-innovation-of-the-year.html",
        title: "Bezdotykové terminály Hikvision MinMoe pre rozpoznanie tváre získali ocenenie Security Hardware Innovation na Security and Fire Excellence Awards 2020"
      },
      {
        link: "https://www.hikvision.com/content/hikvision/cz/newsroom/latest-news/2020/hikvision-announces-touch-free-minmoe-face-recognition-terminals.html",
        title: "Hikvision uvádza „bezdotykové“ terminály rozpoznania tváre MinMoe"
      },
    ],
    videos: [
      {
        link: "https://www.youtube.com/embed/I29_WWuntxs",
        title: "Hikvision MinMoe Iris Recognition Terminal"
      },
      {
        link: "https://www.youtube.com/embed/3-wFa8eWZ7E",
        title: "Hikvision MinMoe Face Recognition Terminals"
      },
    ],
  },
  {
    header: "Malé a stredné podniky, obchody",
    uses: [
      "pre ochranu majetku, zamestnancov, zákazníkov a návštevníkov",
      "nenápadné kamery pre prevenciu a riešenie sporných situácií pri krádežiach v čase predaja",
      "pre zabezpečenie a monitorovanie stavu predajne",
      "pre zlepšenie riadenia predajne prostredníctvom kamery počítajúcej osoby alebo zaznamenávajúcej tepelnú mapu"
    ],
    articles: [
      {
        link: "https://www.hikvision.com/cz/solutions/solutions-by-scenario/store/",
        title: "Obchody"
      },
      {
        link: "https://www.hikvision.com/cz/newsroom/latest-news/2022/hikvision-unveils-new-range-of-pan-tilt-cameras-to-help-smb-users-do-more-with-less/",
        title: "Hikvision uvádza novú radu pan & tilt kamier, ktoré pomáhajú malým a stredným podnikom"
      },
      {
        link: "https://www.hikvision.com/cz/newsroom/latest-news/2022/hikvision-s-end-to-end-solutions-establish-essential-security-for-smbs/",
        title: "Hikvision end-to-end riešenia - základné zabezpečenie pre malé a stredné podniky"
      },
      {
        link: "https://www.hikvision.com/cz/solutions/solutions-by-industry/Retail/",
        title: "Maximalizujte podnikanie"
      },
      {
        link: "https://www.hikvision.com/cz/solutions/solutions-for-small-businesses/cool-security-technologies/",
        title: "Starajte sa lepšie o svoj business s cool bezpečnostnými technológiami"
      },
    ],
  },
  {
    header: "Hotely, penzióny",
    uses: [
      "pre bezpečnosť hostí", "kamery nenarúšajúce pohodu hostí a pokrývajúce celú dlžku chodby",
    ],
  },
  {
    header: "Výroba a sklady",
    uses: [
      "pre monitorovanie niektorého konkrétneho procesu vo výrobe",
      "pre sledovanie situácie vo výrobných halách na zabezpečenie plynulosti výroby a bezpečnosti pracovníkov",
      "pre zabránenie krádežiam v skladoch", "pre monitorovanie firemného priestoru mimo budov",
    ],
    articles: [
      {
        link: "https://www.hikvision.com/cz/solutions/solutions-by-scenario/factory/",
        title: "Továrne. Zabezpečenie a účinný spôsob manažovania fabriky."
      },
    ],
  },
  {
    header: "Budovy s kanceláriami",
    uses: [
      "pre povoľovanie vstupu do budovy prostredníctvom video intercomu a dverovej stanice, voliteľne napr. s kartami na povolenie vstupu",
      "monitorovanie stavu na chodbách a v miestnostiach",
    ],
    articles: [
      {
        link: "https://www.hikvision.com/cz/solutions/solutions-by-scenario/office/",
        title: "Kancelárie"
      },
      {
        link: "https://www.hikvision.com/cz/solutions/solutions-by-scenario/apartment/",
        title: "Byty"
      },
      {
        link: "https://www.hikvision.com/cz/solutions/solutions-by-industry/Building/",
        title: "Vytvorenie bezpečného prostredia pre prácu a život"
      },
    ],
  },
  {
    header: "Mestá",
    uses: [
      "pre zabezpečenie bezpečnosti ľudí na námestiach",
      "pre zabezpečenie bezpečnosti na štadiónoch, tréningových plochách",
      "pre zabezpečenie majetku mesta v budovách",
      "pre zabezpečenie neporušenia kritických objektov",
      "pre zabezpečenie bezpečnosti na autobusových, železničných staniciach",
      "pre monitorovanie bezpečnostnej situácie a príp. robenie preventívnych opatrení"
    ],
    articles: [
      {
        link: "https://www.hikvision.com/cz/products/Alarm-Products/Emergency-Alarm/",
        title: "Rýchla reakcia na potreby zabezpečenia"
      },
      {
        link: "https://www.hikvision.com/cz/newsroom/latest-news/2021/discover-hikvision-s-latest-colorvu-technology--even-sharper-ima/",
        title: "Hikvision technológia ColorVu - ešte ostrejší obraz s jasnejšími farbami, 24 hodín denne"
      },
    ],
  },
  {
    header: "Školy",
    uses: [
      "pre zabezpečenie bezpečnosti žiakov aj učiteľov",
      "pre prevenciu šikanovania a riešenie incidentov",
      "pre ochranu majetku",
      "pre udržiavanie a kontrolu obmedzeného prístupu v budove a na školskom dvore",
      "diaľkové vzdelávanie"
    ],
    articles: [
      {
        link: "https://www.hikvision.com/cz/solutions/solutions-by-industry/education/",
        title: "Záruka bezpečia pre školské prostredie"
      },
      {
        link: "https://www.hikvision.com/cz/solutions/solutions-by-scenario/classroom-hub/",
        title: "Trieda"
      },
      {
        link: "https://www.hikvision.com/cz/solutions/solutions-by-function/information-release/",
        title: "Poskytovanie informácií"
      },
      {
        link: "https://www.hikvision.com/cz/solutions/solutions-by-function/distance-learning/",
        title: "Diaľkové vzdelávanie"
      },
    ],
  },
  {
    header: "Nemocnice",
    uses: [
      "pre monitorovanie stavu pacienta",
      "pre monitorovanie stavu kritických zariadení a zdravotníckeho materiálu",
      "pre monitorovanie priestorov s obmedzeným vstupom",
      "v čakárňach, či tam nie je niekto vo vážnom stave",
    ],
    articles: [
      {
        link: "https://www.hikvision.com/cz/solutions/solutions-by-industry/healthcare/",
        title: "Zdravotnictvo"
      },
      {
        link: "https://www.hikvision.com/cz/solutions/solutions-by-industry/healthcare/security/",
        title: "Zdravotnictvo - bezpečnosť"
      },
      {
        link: "https://www.hikvision.com/cz/solutions/solutions-by-industry/healthcare/automation/",
        title: "Zdravotnictvo - automatizácia"
      },
      {
        link: "https://www.hikvision.com/cz/newsroom/blog/protecting-the-elderly-and-vulnerable-with-intelligent-fall-detection/",
        title: "Ochrana starších a zraniteľných osôb pomocou inteligentnej detekcie pádu"
      },
    ],
  },
  {
    header: "Výskum",
    uses: [
      "sledovanie procesu vo výskume",
      "kamery pre sledovanie zmeny tepla, teploty",
    ],
  },
  {
    header: "Korozívne alebo výbušné prostredie",
    uses: [
      "proti výbuchu", "proti korózii",
    ],
    articles: [
      {
        link: "https://www.hikvision.com/cz/newsroom/latest-news/2022/more-security-and-peace-of-mind-with-hikvision-explosion-proof-and-anti-corrosion-cameras/",
        title: "Lepšia bezpečnosť a väčší kľud s kamerami Hikvision s ochranou proti výbuchu a proti korózii"
      },
      {
        link: "https://www.hikvision.com/content/hikvision/cz/newsroom/latest-news/2019/smart-thermal-camera-has-explosive-performance-in-git-security-a.html",
        title: "Smart Thermal camera has explosive performance in GIT Security Award 2020"
      },
      {
        link: "https://www.hikvision.com/cz/products/IP-Products/Explosion-Proof---Anti-corrosion-Series/",
        title: "Explosion-proof and anti-corrosion series cameras"
      },
    ],
  },
  {
    header: "Parkoviská",
    uses: [
      "monitorovanie priestoru parkoviska", "automatický systém otvárajúci závoru podľa povolených ŠPZ, s využitím kamery rozpoznávajúcej ŠPZ prichádzajúcich vozidiel",
    ],
    articles: [
      {
        link: "https://www.hikvision.com/cz/solutions/solutions-by-scenario/parking-management-solution/",
        title: "Využitie kamier pre inovativne, bezpečné a efektívne parkovanie"
      },
      {
        link: "https://www.hikvision.com/cz/solutions/solutions-by-function/entrance-exit-management/",
        title: "Správa vjazdu a výjazdu"
      },
    ],
  },
  {
    header: "Logistika",
    articles: [
      {
        link: "https://www.hikvision.com/cz/solutions/solutions-by-industry/logistics/",
        title: "Logistické riešenie - dodávateľský reťazec pod kontrolou"
      },
      {
        link: "https://www.hikvision.com/cz/newsroom/blog/smart-logistics-management-helps-you-boost-performance-and-security/",
        title: "Inteligentná správa logistiky pomáha zvýšiť výkon a zabezpečenie"
      },
    ],
  },
  {
    header: "Čerpacie stanice",
    uses: [
      "monitorovanie priestoru čerpacej stanice vonku a vnútri",
      "kamery rozpoznávajúce ŠPZ vozidiel",
    ],
  },
  {
    header: "Banky, herne",
    uses: [
      "pre demonštrovanie bezpečnosti v inštitúcii",
      "pre prevenciu krádeží",
    ],
    articles: [
      {
        link: "https://www.hikvision.com/cz/solutions/solutions-by-industry/banking/",
        title: "Zabezpečenie majetku a podpora prosperity"
      },
    ],
  },
  {
    header: "Doprava",
    uses: [
      "podpora detekcie viacero dopravných priestupkov",
      "meranie rýchlosti",
      "rozpoznanie ŠPZ",
    ],
    articles: [
      {
        link: "https://www.hikvision.com/cz/solutions/solutions-by-industry/traffic/",
        title: "Inteligentné dopravné riešenie"
      },
      {
        link: "https://www.hikvision.com/cz/solutions/solutions-by-scenario/urban-roadways/",
        title: "Mestské dopravné komunikácie"
      },
      {
        link: "https://www.hikvision.com/cz/solutions/solutions-by-function/speed-measurement/",
        title: "Meranie rýchlosti"
      },
      {
        link: "https://www.hikvision.com/cz/solutions/solutions-by-function/vehicle-information-detection/",
        title: "Identifikácia vozidla"
      },
      {
        link: "https://www.hikvision.com/cz/solutions/solutions-by-function/violation-detection/",
        title: "Detekcia porušenia pravidiel cestnej premávky"
      },
      {
        link: "https://www.hikvision.com/content/hikvision/cz/newsroom/latest-news/2020/reduce-road-accidents-and-minimize-congestion-with-hikvision-s-traffic-violation-detection-solution.html",
        title: "Znížte dopravné nehody a minimalizujte dopravné zápchy pomocou Hikvision riešenia detekcie narušenia prevádzky"
      },
      {
        link: "https://www.hikvision.com/content/hikvision/cz/newsroom/latest-news/2021/hikvision-launches-new-its-camera.html",
        title: "Hikvision uvádza na trh novú kameru ITS pre zlepšenie bezpečnosti a plynulosti cestnej prevádzky"
      },
      {
        link: "https://www.hikvision.com/content/hikvision/cz/newsroom/latest-news/2021/make-faster--better-traffic-management-decisions-with-hikvision-traffic-visualization-dashboard.html",
        title: "Rýchlejšie a lepšie rozhodnutia o riadení prevádzky s Hikvision dopravným dashboardom pre vizualizaciu prevádzky"
      },
      {
        link: "https://www.hikvision.com/cz/products/ITS-Products/",
        title: "Produkty inteligentného dopravného systému (ITS)"
      },
      {
        link: "https://www.hikvision.com/cz/products/Mobile-Products/Portable-Products/Portable-Camera-Series/?category=Mobiln%C3%AD+produkty&subCategory=P%C5%99enosn%C3%A9+produkty&series=%C5%98ada+p%C5%99enosn%C3%BDch+kamer&checkedSubSeries=Portable+Camera+Accessories%3BPortable+PTZ+Cameras",
        title: "Prenosné kamery"
      },
    ],
  },
  {
    header: "Autobusy, vlaky, nákladné autá, taxi",
    uses: [
      "kamery pre monitorovanie vnútorného priestoru aj kamery pre sledovanie vonkajšej situácie na ceste",
      "pre zabezpečenie bezpečnosti vodiča a cestujúcich",
      "pre zaznamenanie havarijnej udalosti pre prípadné právne rozhodnutie v spore",
      "Komerčné vozidlá hociktorého typu takisto majú prospech z efektívneho onboard video dohľadu pre zvýšenie bezpečnosti vodiča, monitorovanie všetkých fáz nakladania a vykladania tovaru, a poskytovanie záznamu činností pre prípad spochybnenia kvality služby."
    ],
    articles: [
      {
        link: "https://www.hikvision.com/cz/solutions/solutions-by-industry/public-transport/",
        title: "Zaistenie kontinuity a pohody cestujících"
      },
      {
        link: "https://www.hikvision.com/cz/products/onboard-security/dash-cameras/dash-cameras/",
        title: "Palubné (on-board) kamery"
      },
    ],
  },
  {
    header: "Polícia",
    articles: [
      {
        link: "https://www.hikvision.com/cz/products/Mobile-Products/Portable-Products/",
        title: "Telové a prenosné kamery"
      },
    ],
  },
  {
    header: "Miesta bez prístupu k elektrine",
    uses: [
      "Športové a kultúrne akcie, farmy, lesy a staveniská s malým prístupom k elektrickej energii.",
      "Izolované miesta, ako sú farmy, prírodné letoviská, a dočasné miesta, ako sú staveniská a každoročné kulturne akcie postrádajú energiu a infraštruktúru a čelia problémom so svetlom a počasím.",
      "Solárne riešenie, které generuje energie aj v drsných poveternostných podmienkach s malým množstvom slnečného svitu",
    ],
    articles: [
      {
        link: "https://www.hikvision.com/cz/newsroom/latest-news/2022/what-makes-solar-powered-cameras-the-ideal-security-solution/",
        title: "Solárne napájané kamery - ideálne bezpečnostné riešenie pre izolované lokality a dočasné aplikácie"
      },
      {
        link: "https://www.hikvision.com/cz/newsroom/latest-news/2021/hikvision-s-solar-powered-4g-standalone-security-solution-stands/",
        title: "Hikvision samostatné bezpečnostné riešenie so solárným napájaním 4G vyniká v cenách GIT 2022"
      },
      {
        link: "https://www.hikvision.com/cz/products/IP-Products/Network-Cameras/solar-powered-standalone-security-camera/?category=S%C3%AD%C5%A5ov%C3%A9+produkty&subCategory=S%C3%AD%C5%A5ov%C3%A9+kamery&series=Solar-Powered+Standalone+Security+Camera&checkedSubSeries=NONE",
        title: "Solárna bezpečnostná kamera"
      },
      {
        link: "https://www.hikvision.com/cz/newsroom/blog/pm-q-a-how-hikvision-solar-cameras-help-provide-security-at-remote-sites/",
        title: "How Hikvision's solar cameras help provide security at remote sites"
      },
    ],
  },
  {
    header: "Prenosné odolné ručné systémy",
    uses: [
      "policajti pri bezpečnostných alebo dopravných situáciách",
      "pracovníci poisťovne napr. pri výjazde s cieľom zdokumentovania reálneho stavu poistnej udalosti",
      "pracovníci pohybujúci sa po území firmy, ktorí potrebujú byť v spojení s centrom, komunikovať a prenášať živý videozáznam do centra",
      "Aj z prenosných systémov u osôb alebo mobilných systémov osadených na vozidlách sa dá prenášať živé video do centra (cez 3G, 4G, WiFi)...",
    ],
    articles: [
      {
        link: "https://www.hikvision.com/cz/solutions/solutions-by-function/portable-enforcement/",
        title: "Odolné kamery, používané za pohybu"
      },
      {
        link: "https://www.hikvision.com/cz/products/Mobile-Products/Portable-Products/",
        title: "Telové a prenosné kamery"
      },
    ],
  }
]

const HikUsesArticlesVideos = () => {
  const hh = hikData.map(({ header, uses, articles, videos }, index) => {

    return (
      <Fragment key={index} >
        <h5>{header}</h5>
        {uses && (<ul>
          {uses.map(u => <li key={u}>{u}</li>)}
        </ul>)}
        {articles && (
          <div>
            {articles.map(({ link, title }, i) => (
              <p key={`${title}-${i}`}>
                <a
                  href={link}
                  target="_blank"
                  rel="noreferrer"

                >
                  {title}
                </a>
              </p>
            ))}
          </div>
        )}
        {videos && (
          <div>
            {videos.map(({ link, title }) =>
              <Video
                videoSrcURL={link}
                videoTitle={title}
                key={title}
              />
            )}
          </div>
        )}
        {index + 1 < hikData.length && <hr />}
      </Fragment>
    )
  })
  return hh
}


const Pouzitia = ({ myStyle }) => {

  return (
    <Box sx={{ ...outerStyle, ...myStyle }}>
      <h4 sx={{ variant: "styles.h4" }}>Použitia Hikvision kamerových systémov</h4>
      <div className="custom">
        <HikUsesArticlesVideos />
      </div>
    </Box>
  )
};

export default Pouzitia;
