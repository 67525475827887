/** @jsx jsx */
import { Box, jsx, Image } from "theme-ui";
import imgEldesPitbullPro from "../../../images/alarmy/fixed/pitbpro.jpg";
import imgEldesPitbull from "../../../images/alarmy/fixed/pitb.jpg";

const defaultStyle = {
  px: [1, 2, 3],
  mx: "auto",
  mb: [1, 2],
  py: [1, 2],
  img: {
    variant: "images.stopercent",
  },
};
const EldesPitbull = ({ myStyle }) => (
  <Box as="section" id="eldesPitbull" sx={{ ...defaultStyle, ...myStyle }}>
    <h4 sx={{ variant: "styles.h4" }}>EZS Pitbull Alarm</h4>
    <p>
      Eldes "Pitbull Alarm" a "Pitbull Alarm Pro" sú EZS ústredne určené pre
      bezdrôtové alarmové systémy. Rozdiel medzi nimi je v dosahu komunikácie s
      bezdrôtovými snímačmi. Pre "Pitbull Alarm" je dosah v budove odhadnutý na
      30m, pre "Pitbull Alarm Pro" na 300m.
    </p>
    <p>
      Obe EZS ústredne sa využívajú pre byty, chaty, garáže, menšie firmy s
      kanceláriami, predajne, menšie domy a pod.
    </p>
    <p>
      Eldes doporučuje "Pitbull Alarm Pro" do alarmových systémov pokrývajúcich
      priestory do 150m².
    </p>
    <br />
    <div>
      <span>
        <Image alt="Eldes Pitbull Pro" src={imgEldesPitbullPro} />
      </span>{" "}
      <span>
        <Image alt="Eldes Pitbull" src={imgEldesPitbull} />
      </span>
    </div>
    <br />
    <p>V ústredni EZS sú zabudované</p>
    <ul>
      <li>pohybový detektor do 10m (imúnný na domáce zvieratá)</li>
      <li>
        mikrofón (na možnosť odpočúvania, čo sa práve odohráva pri ústredni, keď
        mobil dostane alarmovú správu)
      </li>
      <li>
        GSM modul (treba osadiť vašu SIM kartu; na komunikáciu s mobilmi max. 10
        užívateľov)
      </li>
      <li>záložná batéria (na 24h pri výpadku el. prúdu)</li>
      <li>
        modul pre pripojenie drôtového snímača a vonkajšej sirény alebo LED
        indikátora
      </li>
      <li>modul pre pripojenie až 16 bezdrôtových zariadení (snímačov)</li>
      <li>miniUSB pre pripojenie k PC - pre nastavenia s Eldes softvérom</li>
    </ul>
    <br />
    <p>
      Poplachový systém je zložený zo 4 nezávisle na sebe konfigurovateľných
      podsystémoch - ako sa systém zachová - alarmové správy a pre ktorých
      definovaných užívateľov.
    </p>
    <br />
    <p>Mobil môžte využiť na</p>
    <ul>
      <li>zapnutie / vypnutie alarmového systému</li>
      <li>
        dostávanie poplachových správ a SMS správ s aktuálnym stavom systému
      </li>
      <li>dostávanie SMS správ o teplote okolia</li>
    </ul>
    <br />
    <p>
      Bezdrôtové zariadenia od Eldes, ktoré možno pripojiť k alarmovému systému
    </p>
    <ul>
      <li>PIR detektory pohybu</li>
      <li>
        kombinované snímače dverového magnetického kontaktu /snímač
        nárazu/snímač zatopenia (vody)
      </li>
      <li>vnútorná siréna</li>
      <li>vonkajšia siréna</li>
      <li>
        kľúčenky (na zapínanie / vypínaniu alarmového systému + 2 tlačidlá na
        ovládanie nejakej definovanej akcie na výstupe napr. zapnutie / vypnutie
        relé)
      </li>
      <li>klávesnice</li>
      <li>
        rozširujúci modul, ku ktorému možno na vstupy pripojiť drôtové snímače a
        ovládať ním na programovateľných výstupoch pripojené napr. relé
        (otváranie/zatváranie brány, osvetlenie, kúrenie. zavlažovanie) alebo
        sirénu
      </li>
      <li>detektor dymu</li>
      <li>kombinovaný detektor dymu a oxidu uhoľnatého (CO)</li>
      <li>obnovovač bezdrôtového signálu</li>
      <li>
        bezdrôtovo ovládateľná elektrická zástrčka (možnosť diaľkového zapínania
        a vypínania v nej zasunutých 230V elektrických zariadení ako svetlá,
        klimatické zariadenia, zavlažovanie, a pod. - cez kľúčenku, klávesnicu,
        SMS, Eldes Cloud alebo podľa časového harmonogramu)
      </li>
    </ul>
    <br />
    <p>Viaceré snímače majú v sebe zabudované aj snímače teploty.</p>
    <p>
      Všetky konfigurovania pre poplachový systém, vrátane aj napr. riadenia
      elektrických zariadení, ktoré sú pripojené k PGM výstupom na asociovaných
      bezdrôtových zariadeniach, alebo reakcie na stratu bezdrôtového spojenia k
      snímaču, sa dajú urobiť v prehľadnej komplexnej Eldes softvérovej
      aplikácii na PC (cez USB), iOS, Androide a na klávesnici. Nastavenia sa
      dajú robiť aj prostredníctvom SMS správ smerovaných na EZS ústredňu z
      prednastavených telefónnych čísel.
    </p>
    <p>
      Okrem mobilu môžte sledovať stav vášho poplachového systému aj na
      internete cez Eldes Cloud.
    </p>
  </Box>
);


export default EldesPitbull;
