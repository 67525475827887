
const COMPONENTS_FILENAMES_IN_TEMPLATE = {
  FullUp: "_fullUp.js",
  MobileUp: "_mobileUp.js",
  MobileUp2: "_mobileUp2.js",
  MainUp: "_mainUp.js",
  MainDown: "_mainDown.js",
  MobileDown: "_mobileDown.js",
  MobileDown2: "_mobileDown2.js",
  FullDown: "_fullDown.js",
  FullDown2: "_fullDown2.js",
}

const COMPONENTS_FILENAMES_IN_LMR = {
  MainCUp: "_mainCUp.js",
  MainCDown: "_mainCDown.js",
  LeftC: "_leftC.js",
  RightC: "_rightC.js",
}

export { COMPONENTS_FILENAMES_IN_TEMPLATE, COMPONENTS_FILENAMES_IN_LMR }