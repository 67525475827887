/** @jsx jsx */
import { Image, jsx } from "theme-ui";
import imgMobil from "../../../images/kamerove-systemy/fixed/mobil-70.png";

const defaultStyle = {
  display: ["none", "block", null, null],
  py: [1, 2],
  my: [1, 2],
};

const ImgColMobil = ({ myStyle }) => (
  <Image
    sx={{ ...defaultStyle, ...myStyle }}
    alt="sledovanie-kamier-na-mobile"
    src={imgMobil}
    loading="lazy"
  />
);

export default ImgColMobil;
