/** @jsx jsx */
import { useState, Fragment } from "react";

import { useForm } from "react-hook-form";
import {
  Text,
  Label,
  Input,
  Textarea,
  Box,
  Button,
  Grid,
  jsx,
  useThemeUI
} from "theme-ui";

import ModalAlert from "../../shared/ModalAlert";


const styleForm = {
  variant: "forms",
  span: { fontWeight: "normal", color: "primary" },
};
const outerStyle = {
  px: [1, 2, 3],
  mx: 0,
  my: [2, 2, 3.4],
  py: [1, 2, 3, 4],
};

const formSendTo =
  "https://5mti2xasmh.execute-api.eu-central-1.amazonaws.com/dev/static-site-mailer";


const propsMeno = {
  name: "Meno",
  errMessage: 'treba uviesť',
  required: true,
  rules: {
    required: 'treba uviesť',

    minLength: 2,
    maxLength: 50,
    // pattern: /^[A-Za-z ]+$/i
  },
  directProps: {
    type: "text",
    id: "meno",
    placeholder: "Vaše meno",
  }
}

const propsEmail = {
  name: "Email",
  errMessage: 'treba uviesť',
  required: true,
  // rules={ required: true, min: 6, pattern: /^\S+@\S+$/i },
  rules: {
    required: 'treba uviesť',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: 'nemá platný tvar (abc@yyy.com)'
    }
  },
  directProps: {
    type: "email",
    id: "email",
    placeholder: "abc@yyy.com",
  }
}


const propsTel = {
  name: "Tel",
  errMessage: '',
  required: false,
  rules: {
    required: false,
    minLength: 7,
    maxLength: 15,
    pattern: /^[0-9 ]+$/i
  },
  directProps: {
    type: "tel",
    id: "tel",
    placeholder: "0987 6543 210",
  }
}

const propsPredmet = {
  name: "Predmet",
  errMessage: 'treba uviesť',
  required: true,
  rules: {
    required: 'musí byť',
    minLength: 5,
    maxLength: 50,
    // pattern: /^[0-9A-Za-z ]+$/i
  },
  directProps: {
    type: "text",
    id: "predmet",
    placeholder: "Predmet správy",
  }
}

const propsSprava = {
  name: "Sprava",
  errMessage: 'treba uviesť',
  required: true,
  rules: {
    required: '- bez nej to nejde',
    minLength: 2,
    // pattern: /^[0-9A-Za-z ]+$/i
  },
  directProps: {
    type: "text",
    id: "sprava",
    placeholder: "Vaša správa",
    multiline: 1, // true,
    rows: 10,
  }
}



const MyInput = ({ register, errors, inputProps }) => {

  const { theme } = useThemeUI()

  const { name, errMessage = "", required = false, rules = { required: false }, directProps } = inputProps

  const errMsg = errors[name] ? errors[name].message : ""
  const nameLabel = required ? `${name}*` : name

  const redIfErr = errors[name] ? theme.colors.danger : theme.colors.borderscolor

  return (
    <Fragment>
      <Label htmlFor={name} >

        {nameLabel.concat(" ", errMsg)}
      </Label>

      {!directProps.rows ? (
        <Input
          name={name}
          {...register(name, rules)}
          {...directProps}
          style={{ borderColor: redIfErr, boxShadow: `0 0 0 2px ${redIfErr}` }}
          aria-invalid={errors[name] ? "true" : "false"}
        />) : (
        <Textarea
          name={name}
          {...register(name, rules)}
          {...directProps}
          style={{
            borderColor: redIfErr,
            boxShadow: `0 0 0 2px ${redIfErr}`,

          }}
          aria-invalid={errors[name] ? "true" : "false"}

        />
      )}
    </Fragment>)
}




const KontaktForm = () => {
  const useFormOut = useForm({
    mode: "onTouched",
  });

  const {
    register,
    handleSubmit,
    formState,
  } = useFormOut

  const {
    errors,
  } = formState;

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState();

  const fnAfterSubmit = () => {
    var myform = document.querySelector("form[name='contact']");
    myform.reset();
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setAlertOpen(false);
  };

  const onSubmit = (data, e) => {

    e.preventDefault();

    fetch(formSendTo, {
      method: "POST",
      headers: {
        // "Content-Type": "application/x-www-form-urlencoded",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })

      .then(() => {
        setAlertMessage(
          `Ďakujeme za správu, ${data.Meno}. Ozveme sa čo najskôr.`
        );
        setAlertOpen(true);
      })
      .catch((error) => alert(error));

  };

  return (
    <Box sx={outerStyle}>
      {alertOpen && (
        <ModalAlert
          message={alertMessage}
          opened={alertOpen}
          functionAfterClose={fnAfterSubmit}
          themeStyle={{ backgroundColor: "primary", color: "primaryAgainst" }}
        ></ModalAlert>
      )}
      <Text>
        Na &nbsp;
        <span role="img" aria-label="email">
          📧
        </span>
        &nbsp; info@pc123.sk nám môžte napísať aj cez tento formulár
      </Text>
      <Box
        as="form"
        sx={styleForm}
        onSubmit={handleSubmit(onSubmit)}
        data-netlify="true"
        method="POST"
        netlify-honeypot="bot-field"
        name="contact"
      >
        <input type="hidden" name="form-name" value="contact" />
        <p style={{ display: "none" }}>
          <label>
            Don’t fill this out: <input name="bot-field" />
          </label>
        </p>

        <MyInput register={register} errors={errors} inputProps={propsMeno} />

        <Grid gap={2} columns={[1, 2]}>
          <div>
            <MyInput register={register} errors={errors} inputProps={propsEmail} />
          </div>
          <div>
            <MyInput register={register} errors={errors} inputProps={propsTel} />
          </div>
        </Grid>

        <MyInput register={register} errors={errors} inputProps={propsPredmet} />

        <MyInput register={register} errors={errors} inputProps={propsSprava} />

        <Button type="submit">Odoslať</Button>
      </Box>
    </Box>
  );
};
export default KontaktForm;
