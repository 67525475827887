import { useStaticQuery, graphql } from 'gatsby';


const useKameryTVImages = () => {
  const data = useStaticQuery(
    graphql`
      {
        kameryTVImages: allFile(
          filter: {
            sourceInstanceName: { eq: "dirImages" }
            relativeDirectory: { eq: "galleries/kamery-gallery-tv" }
          }
        ) {
          nodes {
            id
            base

            childImageSharp {
              id

              original {
                src
              }

              thumb100: resize(height: 100) {
                src
              }
              imgSlider: resize(height: 175) {
                src
              }


            }
          }
        }
      }
    `
  );
  return data.kameryTVImages.nodes;
};
export default useKameryTVImages;
