/** @jsx jsx */
import { Box, jsx } from "theme-ui";

const outerStyle = {
  width: "100%",
  padding: [1, 2, 3, 4],
  mx: "auto",
  my: [1, 2, 3, 4],
  p: [1, 2, 3, 4],
  textAlign: "center",
};

const KvalitaHik = () => (
  <Box sx={outerStyle}>
    <div sx={{ textAlign: "center", my: 1 }}>
      Aj cenovo výhodné riešenia Hikvision majú:
    </div>
    <div>kamery s ostrým, jasným obrazom,</div>
    <div>
      s možnosťou využitia viacero detekcií pre spustenie alarmov a notifikácií,
    </div>
    <div>s efektívnym ukladaním dát,</div>
    <div>s intuitívnym softvérom pre rýchle hľadanie vo videozáznamoch.</div>
  </Box>
);

export default KvalitaHik;
