/** @jsx jsx */
import { jsx, Box, Image } from "theme-ui";

import imgVratnikPro from "../../../images/videovratniky/videovratnik-pro.png"
import LogoLinkHome from "../../shared/LogoLinkHome";
import KontaktInfo from "../../shared/KontaktInfo";

const defaultStyle = {
  px: [null, 0, 1, 2],
  mx: [null, 0, 1, 2],
  pt: [null, 1, 2, 3],
  mt: [null, 1, 2, 3],
  pb: [null, 0, 2, 3],
  mb: [null, 0, 2, 3],
  textAlign: "center",
  display: ["none", "block"],
  '& > img': {
    p: [null, 0, 1, 2]
  }
};

const styleWrap = {
  display: "contents"
}

const LeftC = ({ children, myStyle }) => (
  <div style={styleWrap}>
    <LogoLinkHome />
    <KontaktInfo />
    <Box as="section" sx={{ ...defaultStyle, ...myStyle }}>
      <Image src={imgVratnikPro} alt="Videovrátniky Pro" />
    </Box>
  </div>
);

export default LeftC;
