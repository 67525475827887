import React from "react";
import Mesta from "../../shared/Mesta";
import LazyWeb7skHtml5Banner from "../../shared/LazyWeb7skHtml5Banner";

const MobileDown = () => (
  <div>
    <div style={{ textAlign: "center" }} >
      <Mesta />
      <LazyWeb7skHtml5Banner isSideCol={false} />
    </div>
  </div>
);

export default MobileDown;
