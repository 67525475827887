import React from "react";
import KameryPraceGallery from "./KameryPraceGallery";

const FullDown = () => (
  <div style={{ display: "flex", flexDirection: "column" }}>
    <KameryPraceGallery />
  </div>
);

export default FullDown;
