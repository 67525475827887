/** @jsx jsx */
import { jsx, Text, Box } from "theme-ui";

const outerStyle = {
  my: [1, 2, 3, 4],
  h3: { textAlign: "center" },
};


const Pc123Info = ({ myStyle }) => (
  <Box sx={outerStyle}>
    <h2>PC123</h2>
    <div>
      <span role="img" aria-label="email">
        📧
      </span>
      E-mail
    </div>
    <hr />
    <h3>
      <a href="mailto:info@pc123.sk">info@pc123.sk</a>
    </h3>
    <div>
      <span role="img" aria-label="telephone">
        ☎️
      </span>
      &nbsp;Mobil
    </div>
    <hr />
    <h3>0940&nbsp;883&nbsp;633</h3>
    <p sx={{ textAlign: "center" }}>volať kedykoľvek, aj cez víkend</p>
    <Text sx={{ textAlign: "center" }}>
      V prípade, ak sa nemôžte dovolať, alebo Vám nezdvíhame mobil, prosím
      pošlite na&nbsp;
      <span role="img" aria-label="email">
        📧
      </span>
      <a href="mailto:info@pc123.sk">info@pc123.sk</a> mail, určite Vám naň čo
      najskôr odpovieme.
    </Text>
  </Box>
);

export default Pc123Info;
