/** @jsx jsx */
import { jsx, Image, Box, Grid } from "theme-ui";
import imgVratnikVnutornaStanica from "../../../images/videovratniky/vnutorna-stanica.png"


const headerStyle = { mt: 1, mb: 1, variant: "styles.h4" };
const defaultStyle = {
    mx: 0,
    p: 1,
    borderColor: "borderscolor",
    borderStyle: "ridge",
    borderWidth: "1px",
    backgroundColor: "accent",
    flexDirection: "column",
};


const VratnikVnutornaStanica = ({ myStyle }) => {

    return (<Box sx={{
        mb: ["2rem", "4rem", "6rem"]
    }}>
        <h4 sx={headerStyle} >Vnútorná stanica</h4>
        <Grid gap={2} columns={[1, "3fr 9fr"]} sx={{ p: ["0.5rem", "1rem",] }} >
            <div sx={{ textAlign: "center" }}>
                <Image src={imgVratnikVnutornaStanica} sx={{ margin: "0 auto " }} alt="Videovrátnik - vnútorná stanica" />
            </div>
            <Box as="section" sx={{ ...defaultStyle, ...myStyle }} >
                <ul>
                    <li>komunikácia s návštevou</li>
                    <li>otváranie vchodových dverí</li>
                    <li>7-palcový displej, 1024 * 600, alebo 10-palcový</li>
                    <li>SD pamäťová karta na nahrávanie video komunikácie</li>
                    <li>možnosť prevedenia s wifi (inštalovanie, konfigurovanie)</li>
                    <li>elektrické napájanie možné cez PoE</li>
                    <li>komunikovanie (ako IP tel.) s inými vnútornými stanicami v tej istej počítačovej sieti</li>
                    <li>ak máte nainštalovaný aj kamerový systém
                        <ul>
                            <li>integrovanie kamery v dverovej stanici do kamerového systému</li>
                            <li>sledovanie obrazu z iných IP kamier v budove</li>
                            <li>NVR rekordér môže nahrávať video aj z kamery v dverovej stanici.</li>
                        </ul>
                    </li>
                    <li>alarmové vstupy na káblové pripojenie alarmových snímačov</li>
                    <ul>
                        <li>Pri spustení alarmu môžte dostať cez internet push notifikáciu.</li>
                        <li>Cez vnútornú stanicu môžte zapínať, vypínať chod alarmov.</li>
                    </ul>
                </ul>

            </Box>
        </Grid>
    </Box>
    )
}

export default VratnikVnutornaStanica