
import React from "react";
import Dolezite from "../../shared/Dolezite";

const MainCDown = ({ myStyle }) => (
  <div>
    <Dolezite />
  </div>
);

export default MainCDown;