/** @jsx jsx */
import { jsx, Box } from "theme-ui";

const outerStyle = { my: ["2rem", "4rem", "6rem"] };
const defaultStyle = {
  mx: 0,
  p: "1rem",
  borderColor: "primary",
  borderStyle: "ridge",
  borderWidth: "1px",
};


const TerminalyVyzvaKObj = ({ myStyle }) => (
  <Box sx={outerStyle}>
    <Box as="section" sx={{ ...defaultStyle, ...myStyle }}>
      <p>
        Hikvision ponúka veľa možností na riadenie prístupu zamestnancov a návštev do vašich budov.
      </p>
      <p>
        Informujte sa u nás, aké sú aktuálne možnosti dodania vám riešenia presne na vaše požiadavky (jednoduché alebo zložitejšie).
      </p>
      <ul>
        Dajte nám vedieť, ktoré funkčnosti sú pre vás podstatné napr.
        <li>koľko vchodových dverí treba ovládať</li>
        <li>akú metódu overenia totožnosti chcete využívať - čítačku kariet, čítačku odtlačkov prstov alebo rozoznávanie tváre</li>
        <li>počet užívateľov</li>
        <li>vašu predstavu realizácie, aké funkčnosti by mal systém obsahovať, aké alarmové udalosti</li>
        <li>či dvere už majú elektrický/magnetický zámok</li>
      </ul>
    </Box>
  </Box>
);

export default TerminalyVyzvaKObj;

