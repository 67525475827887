import { useStaticQuery, graphql } from 'gatsby';

export const useStaticKameryPraceImages = () => {
  const data = useStaticQuery(
    graphql`{
  kameryPraceImages: allFile(
    filter: {sourceInstanceName: {eq: "dirStaticStructuredDataImages"}, relativeDirectory: {eq: "kamerove-systemy"}}
  ) {
    nodes {
      id
      base
      childImageSharp {
        id
        original {
          src
          height
          width
           }
           gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            layout: CONSTRAINED
          )
        thumb100: resize(height: 100) {
          src
        }
        imgSlider: resize(height: 175) {
          src
        }
        imgGrid: resize(height: 180) {
          src
          width
          height
        }


      }
    }
  }
}
`
  );
  return data.kameryPraceImages.nodes;
};
