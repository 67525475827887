/** @jsx jsx */
import { Box, jsx } from "theme-ui";
import Video from "../../shared/Video";

const outerStyle = {
  width: "100%",
  borderColor: "primary",
  borderStyle: "ridge",
  borderWidth: "1px",
  padding: ["0.5rem", "1rem"],
  mx: "auto",
  my: ["2rem", "4rem", "6rem"],
};
const VideoVratniky = () => {
  return (
    <Box sx={outerStyle}>
      <Video
        videoSrcURL="https://www.youtube-nocookie.com/embed/videoseries?list=PLgvgXxvr9r9BaC_mypC3L7MPkLFZ3RtkE"
        videoTitle="Video Intercom"
      />
    </Box>
  );
};
export default VideoVratniky;


