/** @jsx jsx */
import { Box, jsx } from "theme-ui";

import imgKlavesnica from "../../images/shared/fixed/klavesnica.jpg";
const defaultStyle = {
  display: ["none", "block", null, null],
  py: [1, 2],
  my: [1, 2],
  "& img": { width: "100%" }
};


const ImgColKlavesnica = ({ myStyle }) => (
  <Box sx={{ ...defaultStyle, ...myStyle }}>
    <img alt="klavesnica" src={imgKlavesnica} loading="lazy" />
  </Box>
);


export default ImgColKlavesnica;
