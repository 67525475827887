/** @jsx jsx */
import { jsx } from "theme-ui";

const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <iframe
    src={videoSrcURL}
    title={videoTitle}
    allow="accelerometer;  encrypted-media; gyroscope; picture-in-picture"
    frameBorder="0"
    webkitallowfullscreen="true"
    mozallowfullscreen="true"
    allowFullScreen
    allowpaymentrequest="false"
    referrerPolicy="no-referrer"
  />
);
export default Video;

