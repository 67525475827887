/** @jsx jsx */
import { jsx, } from "theme-ui"; // useThemeUI
import { lazy, Suspense } from "react"
import {
  useBreakpointIndex
} from '@theme-ui/match-media'

// https://github.com/YIZHUANG/react-multi-carousel

import "react-multi-carousel/lib/styles.css";
import "./myReactMultiCarousel.css";
import { BREAKPOINTS } from "../../../constants"


const LazyCarousel = lazy(() =>
  import(/* webpackMode: "lazy" */ /* webpackChunkName: 'lazy-react-multi-carousel' */  "react-multi-carousel")
);

const themeBreakpointsValues = Object.entries(BREAKPOINTS).map(([key, val]) => val);
const themeBreakpointsKeys = Object.entries(BREAKPOINTS).map(([key, val]) => key);

const MyReactMultiCarousel2 = ({
  multiCarouselOptions = {},
  children,
  mySlider = "MySlider"
}) => {

  // `useBreakpointIndex` returns the index of the currently matched media query:
  const currentMatchedBreakpointIndex = useBreakpointIndex()
  const deviceType = themeBreakpointsKeys[currentMatchedBreakpointIndex - 1]
  const defaultMultiCarouselOptions = {
    additionalTransfrom: 0,
    arrows: true,
    autoPlay: false,
    autoPlaySpeed: 5000,
    centerMode: true,
    className: `${mySlider}`,
    containerClass: `${mySlider}-container`,
    dotListClass: `${mySlider}-dotList`,
    draggable: true,
    focusOnSelect: false,
    infinite: false,
    itemClass: `${mySlider}-item`,
    keyBoardControl: true,
    minimumTouchDrag: 80,
    renderButtonGroupOutside: false,
    renderDotsOutside: false,
    sliderClass: `${mySlider}-slider`,

    responsive: {
      xl: {
        breakpoint: {
          max: 8000,
          min: themeBreakpointsValues[4] + 1,
        },
        items: 5,
      },
      lg: {
        breakpoint: {
          max: themeBreakpointsValues[4],
          min: themeBreakpointsValues[3] + 1,
        },
        items: 5,
      },
      md: {
        breakpoint: {
          max: themeBreakpointsValues[3],
          min: themeBreakpointsValues[2] + 1,
        },
        items: 5,
      },
      sm: {
        breakpoint: {
          max: themeBreakpointsValues[2],
          min: themeBreakpointsValues[1] + 1,
        },
        items: 2,
      },
      xs: {
        breakpoint: {
          max: themeBreakpointsValues[1],
          min: 0,
        },
        items: 1,
      },
    },


    showDots: false,
    slidesToSlide: 1,
    swipeable: true,
    deviceType: deviceType
  };
  const resultMultiCarouselOptions = {
    ...defaultMultiCarouselOptions,
    ...multiCarouselOptions,
  };
  return (
    <Suspense fallback={<div className="loader" />}>
      <LazyCarousel {...resultMultiCarouselOptions} ssr key={mySlider} >
        {children}
      </LazyCarousel>
    </Suspense>
  );
};

export default MyReactMultiCarousel2;
