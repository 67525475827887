/** @jsx jsx */
import { Suspense, lazy } from "react";
import { jsx, Box } from "theme-ui";
import MyLightGalleryProvider from "../../shared/MyLightGalleryProvider";

const LazyMobilAlarmSettingsImagesReactMultiCarouselLightGallery = lazy(() =>
  import(/* webpackMode: "lazy" */ /* webpackChunkName: 'LazyMobilAlarmSettingsImages' */ `./MobilAlarmSettingsImagesReactMultiCarousel-LightGallery`)
);
const LazyAlarmyReactMultiCarouselLightGallery = lazy(() =>
  import(/* webpackMode: "lazy" */ /* webpackChunkName: 'LazyAlarmy' */ `./AlarmyReactMultiCarousel-LightGallery`)
);

const outerStyle = {
  mx: [1, 2, 3, 4],
};

const FullDown = () => (
  <Box as="section" sx={outerStyle}>
    <MyLightGalleryProvider galleryClassName="nastavenieAlarmuNaMobile">
      <Suspense fallback={<div className="loader" />}>
        <LazyMobilAlarmSettingsImagesReactMultiCarouselLightGallery />
      </Suspense>
    </MyLightGalleryProvider>
    <MyLightGalleryProvider galleryClassName="alarmy-prace">
      <Suspense fallback={<div className="loader" />}>
        <LazyAlarmyReactMultiCarouselLightGallery />
      </Suspense>
    </MyLightGalleryProvider>
  </Box>
);

export default FullDown;
