import React from "react";
import NavLeft from "./NavLeft";
import ImgColMobil from "./ImgColMobil";
import LogoLinkHome from "../../shared/LogoLinkHome";
import KontaktInfo from "../../shared/KontaktInfo";

const styleWrap = {
  display: "contents"
}

const LeftC = () => (
  <div style={styleWrap}>
    <LogoLinkHome />
    <KontaktInfo />
    <ImgColMobil />
    <NavLeft />
  </div>
);

export default LeftC;
