import React, { lazy, Suspense, memo } from 'react';

/** LazyDynamicComponent
 * get lazy loading + split via relative path prop
 * React at least 18
 * https://webpack.js.org/api/module-methods/#magic-comments
 * @param  {} {relPath
 * @param  {} compProps={}
 * @param  {} isQuick=false}
 */
const LazyDynamicComponent = ({ relPath, chunkName, compProps = {}, isQuick = false }) => {
    const LazyComp = isQuick ? lazy(() => import(
        /* webpackMode: "eager" */
        `../pagesContents/${relPath}`
    )) : lazy(() => import(
        /* webpackMode: "lazy" */
        `../pagesContents/${relPath}`
    ))
    return (
        <Suspense fallback={<div className="loader" />}>
            <LazyComp {...compProps} />
        </Suspense>
    );
}

export default memo(LazyDynamicComponent)
