/** @jsx jsx */
import { jsx, Box, Image } from "theme-ui";
import LogoLinkHome from "../../shared/LogoLinkHome";
import KontaktInfo from "../../shared/KontaktInfo";
import imgFingerTerminalPro from "../../../images/terminaly/201-finger-pro.png"
import imgFingerTerminal from "../../../images/terminaly/DS-K1T500SF1-finger.png"

const defaultStyle = {
  px: [null, 0, 1, 2],
  mx: [null, 0, 1, 2],
  pt: [null, 1, 2, 3],
  mt: [null, 1, 2, 3],
  pb: [null, 0, 2, 3],
  mb: [null, 0, 2, 3],
  textAlign: "center",
  display: ["none", "block"],
  '& > img': {
    p: [null, 0, 1, 2]
  }
};

const styleWrap = {
  display: "contents"
}

const LeftC = ({ children, myStyle }) => (
  <div style={styleWrap}>
    <LogoLinkHome />
    <KontaktInfo />
    <Box as="section" sx={{ ...defaultStyle, ...myStyle }}>
      <Image src={imgFingerTerminalPro} alt="Finger Terminal Pro" />
      <Image src={imgFingerTerminal} alt="Finger Terminal" />
    </Box>
  </div>

);

export default LeftC;
