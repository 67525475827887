/** @jsx jsx */
import { jsx, Flex, Box } from "theme-ui";

const outerStyle = { mb: [1, 2, 3, 4] };
const defaultStyle = {
  mx: 0,
  p: [1, 2, 3, 4],
  borderColor: "borderscolor",
  borderStyle: "ridge",
  borderWidth: "1px",
  backgroundColor: "accent",
};

const CenaInstalacie = ({ myStyle }) => (
  <Box sx={outerStyle}>
    <span role="img" aria-label="info cena">
      ℹ️
    </span>

    <Flex as="section" sx={{ ...defaultStyle, ...myStyle }}>
      <p>
        Cena inštalácie kamerového, alebo alarmového systému sa dá presnejšie
        stanoviť až po predchádzajúcej obhliadke.
      </p>
    </Flex>
  </Box>
);

export default CenaInstalacie;
