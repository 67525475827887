/** @jsx jsx */
import { jsx, Image } from "theme-ui";
import { Link } from "gatsby";
import logo from "../../images/shared/fixed/pc123-kamerove-systemy-alarmy -logo-min.png";

const outerStyle = {
  display: ["none", "block", null, null],
};

const LogoLinkHome = () => (
  <Link sx={outerStyle} to="/" className="nav-link">
    <Image alt="logoM" src={logo} />
  </Link>
);

export default LogoLinkHome;
