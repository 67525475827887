/** @jsx jsx */
import { lazy, Suspense } from "react";
import { jsx, Box } from "theme-ui";
import Dolezite from "../../shared/Dolezite";

const LazyAlarmProductsTabs = lazy(() =>
  import(/* webpackMode: "lazy" */ /* webpackChunkName: 'LazyAlarmProductsTabs' */ `./AlarmProductsTabs`)
);

const outerStyle = {
  mx: [1, 2, 3, 4],
};

const FullDown2 = () => (
  <Box as="section" sx={outerStyle} key="fullDownI">
    <Suspense fallback={<div className="loader" />}>
      <LazyAlarmProductsTabs />
    </Suspense>
    <Dolezite />
  </Box>
);

export default FullDown2;
