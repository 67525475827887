/** @jsx jsx */
import { Box, jsx } from "theme-ui";
import Video from "../../shared/Video";

const outerStyle = {
  width: "100%",
  borderColor: "primary",
  borderStyle: "ridge",
  borderWidth: "1px",
  p: "1rem",
  mx: "auto",
  my: ["2rem", "4rem", "6rem"]
};
const VideoFaceTerminaly = () => {
  return (
    <Box sx={outerStyle}>
      <Video
        videoSrcURL="https://www.youtube-nocookie.com/embed/I29_WWuntxs"
        videoTitle="Hikvision MinMoe Iris Recognition Terminal"
      />
      <Video
        videoSrcURL="https://www.youtube-nocookie.com/embed/3-wFa8eWZ7E"
        videoTitle="Hikvision MinMoe Face Recognition Terminals"
      />
    </Box>
  );
};
export default VideoFaceTerminaly;


