import React, { lazy, Suspense } from "react";
import { Box } from "theme-ui";
import PripojenieCezInternet from "./PripojenieCezInternet";
import MyLightGalleryProvider from "../../shared/MyLightGalleryProvider";


const LazyKameryPCReactMultiCarouselLightGallery = lazy(() =>
  import(/* webpackMode: "lazy" */ /* webpackChunkName: 'LazyKameryPC' */ `./KameryPcReactMultiCarousel-LightGallery`)
);

const LazyKameryTVReactMultiCarouselLightGallery = lazy(() =>
  import(/* webpackMode: "lazy" */ /* webpackChunkName: 'LazyKameryTV' */ `./KameryTvReactMultiCarousel-LightGallery`)
);

const LazyKameryAntenyReactMultiCarouselLightGallery = lazy(() =>
  import(/* webpackMode: "lazy" */ /* webpackChunkName: 'LazyKameryAnteny' */ `./KameryAntenyReactMultiCarousel-LightGallery`)
);

const LazyKameryZNasichPracReactMultiCarouselLightGallery = lazy(() =>
  import(/* webpackMode: "lazy" */ /* webpackChunkName: 'LazyKameryZNasichPracReactMultiCarousel' */ `./KameryZNasichPracReactMultiCarousel-LightGallery`)
);

const LazyKameryMobilReactMultiCarouselLightGallery = lazy(() =>
  import(/* webpackMode: "lazy" */ /* webpackChunkName: 'LazyKameryMobil' */ `./KameryMobilReactMultiCarousel-LightGallery`)
);



const FullDown = () => (
  <Box>
    <PripojenieCezInternet />
    <div style={{ border: "groove" }}></div>
    <MyLightGalleryProvider galleryClassName="kamery-tv">
      <Suspense fallback={<div className="loader" />}>
        <LazyKameryTVReactMultiCarouselLightGallery />
      </Suspense>
    </MyLightGalleryProvider>
    <MyLightGalleryProvider galleryClassName="kamery-pc">
      <Suspense fallback={<div className="loader" />}>
        <LazyKameryPCReactMultiCarouselLightGallery />
      </Suspense>
    </MyLightGalleryProvider>
    <MyLightGalleryProvider galleryClassName="kamery-mobil">
      <Suspense fallback={<div className="loader" />}>
        <LazyKameryMobilReactMultiCarouselLightGallery />
      </Suspense>
    </MyLightGalleryProvider>
    <MyLightGalleryProvider galleryClassName="kamery-anteny">
      <Suspense fallback={<div className="loader" />}>
        <LazyKameryAntenyReactMultiCarouselLightGallery />
      </Suspense>
    </MyLightGalleryProvider>
    <MyLightGalleryProvider galleryClassName="kamery-prace">
      <Suspense fallback={<div className="loader" />}>
        <LazyKameryZNasichPracReactMultiCarouselLightGallery />
      </Suspense>
    </MyLightGalleryProvider>
  </Box>
);

export default FullDown;

