import React from "react";
import Mesta from "../../shared/Mesta";
import { Box } from "theme-ui";
import LazyWeb7skHtml5Banner from "../../shared/LazyWeb7skHtml5Banner";

const MobileDown = () => (
  <Box>
    <div style={{ textAlign: "center" }} >
      <Mesta />
      <LazyWeb7skHtml5Banner isSideCol={false} />
    </div>
  </Box>
);

export default MobileDown;
