/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import FaceTerminaly from "./FaceTerminaly"
import TerminalySoftver from "./TerminalySoftver"
import TerminalyVyzvaKObj from "./TerminalyVyzvaKObj"
import VideoFaceTerminaly from "./VideoFaceTerminaly"

const outerStyle = {
  mx: [1, 2, 3, 4],
};


const FullDown = () => (
  <Box as="section" sx={outerStyle}>
    <FaceTerminaly />
    <TerminalySoftver />
    <TerminalyVyzvaKObj />
    <VideoFaceTerminaly />
    <p>
      <a
        href="https://www.hikvision.com/cz/products/Access-Control-Products/"
        target="_blank"
        rel="noreferrer"
      >
        Hikvision systémy riadenia vstupu
      </a>
    </p>
  </Box>
);

export default FullDown;
