/** @jsx jsx */
import { Grid, Box, jsx, Image } from "theme-ui";
import imgESIM384 from "../../../images/alarmy/fixed/esim2.jpg";
import imgEsimKlavesnica from "../../../images/alarmy/fixed/klav.jpg";
import imgEsimKlucenka from "../../../images/alarmy/fixed/kluc.jpg";


const defaultStyle = {
  px: [1, 2, 3],
  mx: "auto",
  mb: [1, 2],
  py: [1, 2],
  img: {
    variant: "images.stopercent",
  },
};


const Esim384 = ({ myStyle }) => {
  return (
    <Box as="section" id="esim384" sx={{ ...defaultStyle, ...myStyle }}>
      <h4 sx={{ variant: "styles.h4" }}>EZS ESIM384</h4>

      <p>
        Alarmové systémy s EZS ústredňami Eldes ESIM. Eldes ESIM384 patrí medzi hybridné EZS ústredne, má integrovaný GSM modul. Dajú sa
        k nej pripojiť aj drôtové aj bezdrôtové zariadenia.
      </p>
      <p>
        Eldes doporučuje ESIM384 do alarmových systémov pokrývajúcich priestory
        do 300m². Na otvorenej ploche dokáže ESIM384 ovládať bezdrôtové
        zariadenia do diaľky 3km.
      </p>
      <p>
        V prípade narušenia objektu Vám môže poslať na 10 predvolených
        telefónnych čísiel upozorňujúcu SMS, prípadne vás priamo prezvoní na váš
        mobil.
      </p>
      <Grid gap={2} columns={["12fr", "2fr 10fr"]}>
        <Image alt="esim384" src={imgESIM384} sx={{ display: ["none", "block"] }} loading="lazy" />
        <Box>
          <div>Môže Vám napr. cez internet</div>
          <ul>
            <li>poslať upozorňujúcu push správu,</li>
            <li>spustiť nahrávanie vášho kamerového systému a</li>
            <li>
              aktuálne snímky s narušiteľom Vám pošle priamo na váš email.
            </li>
          </ul>
          <p>
            Proti výpadku el. napájania je chránená záložnou batériou, takže
            bude pracovať a aj vás upozorní pri výpadku el. prúdu.
          </p>
        </Box>
      </Grid>
      <Grid gap={2} columns={["12fr", "2fr 10fr"]}>
        <Image alt="ESIM klávesnica" src={imgEsimKlavesnica} sx={{ display: ["none", "block"] }} loading="lazy" />
        <Box>
          <div>EZS ústredňa umožňuje alarmovému systému pripojiť</div>
          <Grid gap={2} columns={[1, 2]} sx={{ maxWidth: 'fit-content' }}>
            <ul>
              <li>rozširujúce GSM a LAN komunikátory,</li>
              <li>
                rozširujúce kontrollery s ďalšími programovateľnými výstupmi,
              </li>
              <li>klávesnice,</li>
              <li>kľúčenky,</li>
              <li>drôtové aj bezdrôtové pohybové detektory,</li>
              <li>detektory dymu,</li>
              <li>detektory rozbitia skla,</li>
            </ul>
            <ul>
              <li>snímače magnetických kontaktov dverí a okien,</li>
              <li>detektory CO,</li>
              <li>detektory vody,</li>
              <li>snímače teploty</li>
              <li>sirénu (vonkajšiu aj vnútornú),</li>
              <li>mikrofón (pre odposluch na diaľku)</li>
            </ul>
          </Grid>
          <p>
            Pokročilý časový harmonogram pre až 4 logické podskupiny alarmového
            systému.
          </p>
        </Box>
      </Grid>
      <Grid gap={2} columns={["12fr", "2fr 10fr"]}>
        <Image src={imgEsimKlucenka} alt="Esim klúčenka" sx={{ display: ["none", "block"] }} loading="lazy" />
        <Box>
          <div>Alarmový systém sa dá zapnúť a vypnúť</div>
          <ul>
            <li>pohodlne bezdrôtovou kľúčenkou,</li>
            <li>klávesnicou,</li>
            <li>z mobilu zaslaním SMS alebo prezvonením a</li>
            <li>cez Eldes Cloud.</li>
          </ul>
        </Box>
      </Grid>
      <p>
        Aktuálny stav vášho alarmového systému si môžete pozrieť v Eldes Cloud,
        odoslaním SMS z mobilu na ústredňu.
      </p>
      <p>
        Info SMS o stave systému odosiela ústredňa na mobil aj podľa nastaveného
        časového harmonogramu.
      </p>
      <p>
        Okrem samotného zabezpečenia domu, umožňuje vytvorenie inteligentnej
        domácnosti...
      </p>
      <p>
        Možnosť riadiť vjazdovú bránu, garážovú bránu, zapnutie a vypnutie
        kúrenia, svetla, chladiaceho systému, reset dymových snímačov do
        obnoveného stavu a pod. cez PGM výstupy.
      </p>
      <p>
        Možnosť diaľkového zapínania a vypínania (cez kľúčenku, klávesnicu, SMS,
        Eldes Cloud alebo podľa časového harmonogramu) 230V elektrických
        zariadení ako svetlá, klimatické zariadenia, zavlažovanie - ak sa zapoja
        do napájacích zásuviek, ku ktorým je možný bezdrôtový prístup.
      </p>
    </Box>
  );
};


export default Esim384;
