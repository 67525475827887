/** @jsx jsx */
import { jsx, Image, Box, Grid } from "theme-ui";
import imgFaceTerminalPro from "../../../images/terminaly/face-pro.png"


const headerStyle = {
    mt: 1,
    mb: 1,
    variant: "styles.h4",
};
const defaultStyle = {
    mx: 0,
    p: "1rem",
    borderColor: "borderscolor",
    borderStyle: "ridge",
    borderWidth: "1px",
    backgroundColor: "accent",
    flexDirection: "column",
};


const FaceTerminaly = ({ myStyle }) => {

    return (<Box sx={{
        mb: ["2rem", "4rem", "6rem"]
    }}>
        <h4 sx={headerStyle} >Tvárové teminály</h4>
        <Grid gap={2} columns={[1, "3fr 9fr"]} sx={{ p: ["0.5rem", "1rem",] }} >
            <div sx={{ textAlign: "center" }}>
                <Image src={imgFaceTerminalPro} sx={{ margin: "0 auto " }} alt="Videovrátnik - vnútorná stanica" />
            </div>
            <Box as="section" sx={{ ...defaultStyle, ...myStyle }} >
                <h4>Prečo tvárové terminály</h4>
                <ul>
                    <li>bezpečné
                        <ul>
                            <li>kľúče, bezkontaktné karty je možné stratiť</li>
                            <li>odtlačky prstov sú nehygienické, nevhodné do prostredia, kde sú nutne používané rukavice, nevhodné pre niektorých ľudí so zdravotným postihnutím</li>
                            <li>správnosť rozoznania tváre &gt; 99% (aj IČ obraz tváre, softvérový deep learning algoritmus, detekcie podstrčenia masky)</li>
                        </ul>
                    </li>

                    <li>pohodlné
                        <ul>
                            <li>bezdotykové, pri overovaní totožnosti môžte niesť v rukách hoci aj veľkú krabicu</li>
                            <li>rýchlosť rozoznania &lt; 0,2s (aj pri malom svetle, dokonca aj za úplného šera)</li>
                            <li>veľmi rýchle, zvláda aj nával ľudí pri príchode do práce</li>
                        </ul>
                    </li>

                </ul>

                <h4>Hikvision tvárové terminály - parametre</h4>
                <ul>
                    <li>vstavaná 2 MP duálna kamera, široký uhol, WDR, pre rozoznanie tváre</li>
                    <li>vzdialenosť pre rozoznanie tváre: 0,3m - 1m</li>
                    <li>kapacita tvárí: až 300, 1500, 10000</li>
                    <li>4.3/7-palcový dotykový displej</li>
                    <li>robenie snímok-fotiek a ich uloženie</li>
                    <li>Ak je nainštalovaný aj kamerový systém
                        <ul>
                            <li>prepojenia ku kamerovému systému: NVR rekordérom, sieťovým kamerám</li>
                            <li>kamera v termináli reagujúc na udalosť môže začať nahrávať, vykonávajúc tak videodohľad v reálnom čase.</li>
                            <li>prezeranie live obrazu z kamery cez NVR</li>
                            <li>napr. záznamy z overenia sa pridajú k video udalostiam a dajú sa prehľadávať použitím manažovacieho softvéru, každá udalosť bude zahŕňať 1 min video záznamu.</li>

                        </ul>
                    </li>
                    <li>mikrofón, potlačenie okolitého šumu a rušenie echa</li>
                    <li>Ak sú nainštalované interkom (videovrátnik) vnútorné stanice, príp. master stanica
                        <ul>
                            <li>videovrátnikové funkcie</li>
                            <li>dvojcestný zvuk s vnútornou a master stanicou</li>
                            <li>zvukový prompt</li>
                        </ul>
                    </li>
                    <li>niektoré aj čítačku kariet (pre kombinovanú autentifikáciu)</li>
                    <li>niektoré aj čítačku odtlačkov prstov</li>
                </ul>
                <p>
                    <a
                        href="https://www.hikvision.com/content/hikvision/cz/newsroom/latest-news/2020/hikvision-win-security-hardware-product-innovation-of-the-year.html"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Bezdotykové terminály Hikvision MinMoe pro rozeznání obličeje získaly ocenění Security Hardware Innovation roku na ocenění Security and Fire Excellence Awards 2020
                    </a>
                </p>
                <p>
                    <a
                        href="https://www.hikvision.com/content/hikvision/cz/newsroom/latest-news/2020/hikvision-announces-touch-free-minmoe-face-recognition-terminals.html"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Společnost Hikvision uvádí „bezdotykové“ terminály rozpoznání obličeje MinMoe
                    </a>
                </p>
                <p>
                    <a
                        href="https://www.hikvision.com/content/hikvision/cz/newsroom/latest-news/2020/how-touch-free-access-control-with-temperature-screening-helps-businesses-in-returning-to-work.html"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Jak „bezdotykové“ řízení přístupu s kontrolou teploty pomáhá podnikům v návratu do práce
                    </a>
                </p>
                <p>
                    <a
                        href="https://www.hikvision.com/cz/newsroom/latest-news/2021/hikvision-reveals-affordable-security-tech-for-small-businesses/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Hikvision odhaluje dostupnou bezpečnostní technologii se šesti působivými bezpečnostními upgrady pro malé podniky
                    </a>
                </p>
            </Box>

        </Grid>
    </Box>
    )
}

export default FaceTerminaly