import React, { Suspense, lazy } from "react";
import { Box } from "theme-ui";

const LazyKameryTabs = lazy(() =>
  import(/* webpackMode: "lazy" */ /* webpackChunkName: 'KameryTabs' */ `./KameryTabs`)
);

const FullDown2 = () => (
  <Box>
    <Suspense fallback={<div className="loader" />}>
      <LazyKameryTabs />
    </Suspense>
  </Box>
);

export default FullDown2;

