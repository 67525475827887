import React from "react";

import ImgColSwitch from "../../shared/ImgColSwitch";
import LogoLinkHome from "../../shared/LogoLinkHome";
import KontaktInfo from "../../shared/KontaktInfo";
import LazyWeb7skHtml5Banner from "../../shared/LazyWeb7skHtml5Banner";

const styleWrap = {
    display: "contents"
}

const LeftC = () => (
    <div style={styleWrap}>
        <LogoLinkHome />
        <KontaktInfo />
        <ImgColSwitch />
        <LazyWeb7skHtml5Banner />
    </div>
);

export default LeftC;
