/** @jsx jsx */
import { jsx } from "theme-ui";
import ImgKameroveSystemy from "./ImgKameroveSystemy"


const MainCDown = ({ myStyle }) => (
  <div>
    <ImgKameroveSystemy />
  </div>
);

export default MainCDown;