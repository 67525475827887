/** @jsx jsx */
import { Flex, Container, Grid, jsx } from "theme-ui";
import { memo } from "react";
import { COMPONENTS_FILENAMES_IN_LMR as comps } from "./mapComponents"
import LazyDynamicComponent from "./LazyDynamicComponent"

const styleCont = {
  width: "100%",
  py: [1, 2, 3],
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
}

const styleMain = {
  flexDirection: "column",
  width: "100%",
  justifyContent: "flex-start",
  position: "relative",
  px: 2,
  my: [0, 2],
  py: [0, 2, 3],
};

const styleLeft = {
  display: ["none", "flex"],
  flexDirection: "column",
  alignItems: "center",
  my: [0, 2],
  py: [0, 2, 3],
  justifyContent: "flex-start",
  mx: [1, 1],
  px: [0, 1, 2, 3],
};


const styleRight = {
  display: ["none", "flex"],
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  mx: [1, 1],
  px: [0, 1, 2, 3],
  my: [0, 2],
  py: [0, 2, 3],
};


const LayoutLMR = (props) => {

  const { children, isMobile, frontmatter, pageContext } = props

  const { contentDir = "notFound" } = pageContext

  const {
    sectionsLMR,
    sectionLMR = "M",
  } = frontmatter;

  let isMainCUp = sectionsLMR?.includes("MainCUp")
  const isMainCDown = sectionsLMR?.includes("MainCDown")

  const isPageNotFound = (contentDir === "notFound") ? true : false
  if (isPageNotFound) isMainCUp = false

  const layoutType = sectionLMR.toUpperCase()

  return (
    <div
      id="LayoutLMR"
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        backgroundColor: "background",
      }}>
      <Container
        id="LayoutLMRCont"
        sx={styleCont}
        key="cont-mainContent"
      >
        {layoutType === "LMR" && (
          <div>
            <Grid
              gap={2}
              columns={[1, "1fr 4fr 1fr", "1fr 3fr 1fr", "1fr 2fr 1fr"]}
              key="gridLMR"
            >
              <Flex as="aside" sx={styleLeft} key="leftFlex-LMR">
                {!isMobile && <LazyDynamicComponent chunkName={`${contentDir}-LeftC`} relPath={`${contentDir}/${comps.LeftC}`} isQuick={true} />}
              </Flex>

              <Flex
                as="main"
                sx={styleMain}
                key="mainFlex-LMR"
              >
                {isMainCUp && <LazyDynamicComponent chunkName={`${contentDir}-MainCUp`} relPath={`${contentDir}/${comps.MainCUp}`} isQuick={true} />}
                {children}
                {isMainCDown && <LazyDynamicComponent chunkName={`${contentDir}-MainCDown`} relPath={`${contentDir}/${comps.MainCDown}`} isQuick={true} />}
              </Flex>
              <Flex as="aside" sx={styleRight} key="rightFlex-LMR">
                {!isMobile && <LazyDynamicComponent chunkName={`${contentDir}-RightC`} relPath={`${contentDir}/${comps.RightC}`} isQuick={true} />}
              </Flex>
            </Grid>
          </div>
        )}

        {layoutType === "MR" && (
          <div>
            <Grid gap={2} columns={[1, "3fr 1fr", "3fr 1fr"]} key="gridMR">

              <Flex
                as="main"
                sx={styleMain}
                key="mainFlex-MR"
              >
                {isMainCUp && <LazyDynamicComponent chunkName={`${contentDir}-MainCUp`} relPath={`${contentDir}/${comps.MainCUp}`} isQuick={true} />}
                {children}
                {isMainCDown && <LazyDynamicComponent chunkName={`${contentDir}-MainCDown`} relPath={`${contentDir}/${comps.MainCDown}`} isQuick={true} />}
              </Flex>
              <Flex as="aside" sx={styleRight} key="rightFlex-MR">
                {!isMobile && <LazyDynamicComponent chunkName={`${contentDir}-RightC`} relPath={`${contentDir}/${comps.RightC}`} isQuick={true} />}
              </Flex>
            </Grid>
          </div>
        )}


        {layoutType === "LM" && (
          <div>
            <Grid gap={2} columns={[1, "1fr 3fr", "1fr 3fr"]} key="gridLM" >
              <Flex as="aside" sx={styleLeft} key="leftFlex-LM">
                {!isMobile && <LazyDynamicComponent chunkName={`${contentDir}-LeftC`} relPath={`${contentDir}/${comps.LeftC}`} isQuick={true} />}
              </Flex>
              <Flex
                as="main"
                sx={styleMain}
                key="mainFlex-LM"
              >
                {isMainCUp && <LazyDynamicComponent chunkName={`${contentDir}-MainCUp`} relPath={`${contentDir}/${comps.MainCUp}`} isQuick={true} />}
                {children}
                {isMainCDown && <LazyDynamicComponent chunkName={`${contentDir}-MainCDown`} relPath={`${contentDir}/${comps.MainCDown}`} isQuick={true} />}
              </Flex>

            </Grid>
          </div>
        )}

        {layoutType === "M" && (
          <Flex
            as="main"
            sx={styleMain}
            key="mainFlex-M"
          >
            {isMainCUp && <LazyDynamicComponent chunkName={`${contentDir}-MainCUp`} relPath={`${contentDir}/${comps.MainCUp}`} isQuick={true} />}
            {children}
            {isMainCDown && <LazyDynamicComponent chunkName={`${contentDir}-MainCDown`} relPath={`${contentDir}/${comps.MainCDown}`} isQuick={true} />}
          </Flex>
        )}
      </Container>
    </div>
  );
};

export default memo(LayoutLMR, (prevProps, nextProps) => {
  if (prevProps.isMobile === nextProps.isMobile && prevProps.pageContext.contentDir === nextProps.pageContext.contentDir) {
    return true; // props are equal
  }
  return false; // props are not equal -> update the component
});
