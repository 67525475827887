import React from "react";
import Dolezite from "../../shared/Dolezite";
import KontaktForm from "./KontaktForm";

const FullDown = () => (
  <div>
    <Dolezite />
    <KontaktForm />
  </div>
);

export default FullDown;
