/** @jsx jsx */
import { jsx } from "theme-ui";
import { useBreakpointIndex } from '@theme-ui/match-media'
import { LightgalleryItem } from "react-lightgallery";
import { Image } from "theme-ui";
import MyReactMultiCarousel2 from "../../shared/myReactMultiCarousel/MyReactMultiCarousel2";
import useMobilAlarmSettingsImages from "./useMobilAlarmSettingsImages";
import { BREAKPOINTS } from "../../../constants"

const sectionStyle = {
  borderColor: "borderscolor",
  borderStyle: "ridge",
  borderWidth: "1px",
  px: [1, 2, 3, 4],
  pt: 0,
  pb: [1, 2, 3, 4],
};

const themeBreakpointsValues = Object.entries(BREAKPOINTS).map(([key, val]) => val);
const themeBreakpointsKeys = Object.entries(BREAKPOINTS).map(([key, val]) => key);

const responsive = {
  xl: {
    breakpoint: {
      max: 8000,
      min: themeBreakpointsValues[4] + 1,
    },
    items: 5,
  },
  lg: {
    breakpoint: {
      max: themeBreakpointsValues[4],
      min: themeBreakpointsValues[3] + 1,
    },
    items: 3,
  },
  md: {
    breakpoint: {
      max: themeBreakpointsValues[3],
      min: themeBreakpointsValues[2] + 1,
    },
    items: 3,
  },
  sm: {
    breakpoint: {
      max: themeBreakpointsValues[2],
      min: themeBreakpointsValues[1] + 1,
    },
    items: 2,
  },
  xs: {
    breakpoint: {
      max: themeBreakpointsValues[1],
      min: 0,
    },
    items: 1,
  },
}


const MobilAlarmSettingsImagesReactMultiCarouselLightGallery = () => {

  const currentMatchedBreakpointIndex = useBreakpointIndex()
  const deviceType = themeBreakpointsKeys[currentMatchedBreakpointIndex]
  const autoPlay = deviceType !== "xs" ? false : false
  const infinite = deviceType !== "xs" ? true : true
  const multiCarouselOptions = { responsive, deviceType, autoPlay, infinite }

  const group = "nastavenieAlarmuNaMobile"
  const mobilAlarmSettingsImagesNodes = useMobilAlarmSettingsImages();
  const arrMobilAlarmSettingsImagesOrig = mobilAlarmSettingsImagesNodes.map((imgNode, i) => {

    const { src } = imgNode.childImageSharp.original
    const thumb = imgNode.childImageSharp.thumb100.src
    const key = imgNode.base
    const srcSlider = imgNode.childImageSharp.imgSlider.src
    const alt = imgNode.base

    return (
      <LightgalleryItem
        group={group}
        src={src}
        thumb={thumb}
        key={key}
        subHtml={`<div>${imgNode.base}</div>`}
      >
        <Image
          src={srcSlider}
          alt={alt}
          variant="slideImg"
        />
      </LightgalleryItem>
    )
  });

  return (
    <div sx={{ my: "2rem" }}>
      <h4 sx={{ variant: "styles.h4" }}>
        Zobrazenie a ovládanie alarmu na mobilnom telefóne cez internet
      </h4>
      <section sx={sectionStyle}>
        <MyReactMultiCarousel2
          multiCarouselOptions={multiCarouselOptions}
          mySlider="alarmMobilOrig"
        >
          {arrMobilAlarmSettingsImagesOrig}
        </MyReactMultiCarousel2>
      </section>
    </div>
  );
};

export default MobilAlarmSettingsImagesReactMultiCarouselLightGallery;

