/** @jsx jsx */
import { Box, jsx, Grid } from "theme-ui";


const outerStyle = {
  width: "100%",
  borderColor: "borderscolor",
  borderStyle: "ridge",
  borderWidth: "1px",
  color: "text",
  padding: [1, 2, 3, 4],
  mx: "auto",
  my: [1, 2, 3, 4],
};

const VyzvaKObj = () => (
  <Box sx={outerStyle}>
    <div sx={{ textAlign: "center", my: 1 }}>
      Navrhneme vám možnosti video dohľadu presne pre vaše priestory
    </div>
    <Grid gap={3} columns={[1, 1, 2]}>
      <Box
        sx={{
          div: { textAlign: ["center", "center", "right"] },
        }}
      >
        <div>vo firme</div>
        <div>v hoteli</div>
        <div>v rodinnom dome</div>
        <div>v bytovom dome</div>
        <div>v obchode</div>
      </Box>
      <Box
        sx={{
          div: { textAlign: ["center", "center", "left"] },
        }}
      >
        <div>v budovách a okolitom priestore</div>
        <div>na parkoviskách</div>
        <div>v doprave</div>
        <div>v škole</div>
        <div>v nemocnici</div>
      </Box>
    </Grid>
    <div sx={{ textAlign: "center", my: 1 }}>
      alebo pre akúkoľvek inú Vašu situáciu
    </div>
  </Box>
);

export default VyzvaKObj;
