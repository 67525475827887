/** @jsx jsx */
import { Grid, Box, jsx } from "theme-ui";
import Licencia from "./Licencia";


const defaultStyle = {
  px: [1, 2, 3],
  mx: 0,
  my: [2, 2, 3, 4],
  py: [1, 2.3, 4],
  borderColor: "borderscolor",
  borderStyle: "ridge",
  borderWidth: "1px",
  backgroundColor: "accent",
  maxWidth: '800px',
  marginLeft: 'auto',
  marginRight: 'auto',
  "#dolezitetext": { my: "auto", textAlign: "center" },
};

const Dolezite = ({ myStyle }) => (
  <Grid
    as="section"
    id="dolezite"
    gap={2}
    columns={["12fr", "3fr 9fr", "3fr 9fr", "3fr 9fr"]}
    sx={{ ...defaultStyle, ...myStyle }}
  ><div sx={{ display: ["none", "block"] }}>
      <Licencia />
    </div>
    <Box id="dolezitetext">
      <div>
        <strong>Dôležité:</strong>
      </div>
      <div>Sme držiteľmi licencie na prevádzkovanie technickej služby</div>
      <div>
        Vykonávame odbornú montáž kamerových a alarmových systémov,
        <p>
          vrátane vystavenia&nbsp;
          <span role="img" aria-label="vystavenie zmluvy">
            ✒️
          </span>
          &nbsp;
          <span sx={{ color: "primary" }}>
            zmluvy o poskytnutí technickej služby
          </span>
          .
        </p>
      </div>
      <div>
        Takto legálne namontovaný systém Vám umožní používať získané záznamy pre
        prípadné
        <div>trestno - právne účely, alebo pri jednaniach s poisťovňami.</div>
      </div>
    </Box>
  </Grid>
);

export default Dolezite;
