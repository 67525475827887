/** @jsx jsx */
import React from "react";
import Modal from "react-modal";
// http://reactcommunity.org/react-modal/
import { Close, jsx, useThemeUI } from "theme-ui";

const styleClose = {
  border: "groove",
  height: "auto",
}
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement("#yourAppElement");

const ModalAlert = (props) => {
  const { theme } = useThemeUI();
  const { rawColors: colors } = theme

  const styleModalAlert = {
    content: {
      position: "absolute",
      border: "1px solid #ccc",
      background: "#fff",
      overflow: "auto",
      WebkitOverflowScrolling: "touch",
      borderRadius: "4px",
      outline: "none",
      padding: "20px",
      textAlign: "center",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: `${colors.headerback}`,
      color: `${colors.headertext}`,
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "transparent",
    },
  };

  const {
    children,
    opened,
    message,
    functionAfterClose,
    themeStyle,
    ...rest
  } = props;
  const [modalIsOpen, setIsOpen] = React.useState(opened);

  function afterOpenModal() {
  }

  function closeModal() {
    setIsOpen(false);
  }


  return (
    <div>
      <Modal
        {...rest}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onAfterClose={functionAfterClose}
        onRequestClose={closeModal}
        style={styleModalAlert}
        contentLabel="Modal Odpoveď"
      >
        <div>{message}</div>

        {children}

        <Close sx={styleClose} onClick={closeModal} />
      </Modal>
    </div>
  );
};

export default ModalAlert;
