import React from "react";
import Mesta from "../../shared/Mesta";
import LazyWeb7skHtml5Banner from "../../shared/LazyWeb7skHtml5Banner";

const styleWrap = {
    display: "contents"
}

const RightC = () => (
    <div style={styleWrap}>
        <Mesta />
        <LazyWeb7skHtml5Banner />
    </div>)

export default RightC;
