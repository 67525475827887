/** @jsx jsx */
import { StaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { jsx } from "theme-ui";


export default function VratnikModulySchemaImage() {
  return (
    <StaticQuery
      query={graphql`{
  file(
    relativePath: {eq: "videovratniky/videovrátnik-moduly-schema.jpg"}
    sourceInstanceName: {eq: "dirImages"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 1200
        breakpoints: [640, 768, 1024, 1280]
        layout: CONSTRAINED
      )
    }
  }
}
`}
      render={(data) => {
        return (
          <GatsbyImage
            image={data.file.childImageSharp.gatsbyImageData}
            style={{ margin: "0 auto", height: "auto", width: "auto" }}
            alt="Videovrátnik Moduly Schéma" />
        );
      }}
    />
  );
}

