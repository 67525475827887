/** @jsx jsx */
import { jsx, Grid, Box, Image } from "theme-ui";
import imgVratnikValue from "../../../images/videovratniky/videovratnik-value.png"

const outerStyle = { my: ["2rem", "4rem", "6rem"], p: ["0.5rem", "1rem",], alignContent: "space-evenly" };
const defaultStyle = {
  display: "inline-flex",
  mx: 0,
  p: 1,
  borderColor: "primary",
  borderStyle: "ridge",
  borderWidth: "1px",
};


const VratnikVyzvaKObj = ({ myStyle }) => (
  <Grid gap={2} columns={[1, 1, "9fr 3fr"]} sx={outerStyle} >
    <Box as="section" sx={{ ...defaultStyle, ...myStyle }}>
      <ul>
        <p>
          Pre zhodnotenie realizovateľnosti a montážnej prácnosti k vám radi prídeme na obhliadku miesta.
        </p>
        Zvážte, ktoré funkčnosti sú pre vás podstatné napr.
        <li>či zamýšľate (teraz alebo v budúcnosti) prepojiť videovrátnik ku kamerovému systému, alebo k systému riadenia prístupu</li>
        <li>koľko vstupných dverí a koľko vnútorných staníc</li>
        <li>či 1.3 MP alebo 2MP HD kamera, či IR prisvietenie </li>
        <li>či montáž dverovej stanice jednoducho na povrch steny alebo so zahĺbením do steny.</li>
        <li>či dvere už majú elektrický/magnetický zámok</li>
        <li>či menovky s tlačidlami</li>
        <li>či bezkontaktné karty</li>
        <li>či displej</li>
        <li>či fyzickú klávesnicu</li>
        <li>či exit tlačidlo</li>
      </ul>
    </Box>
    <Box sx={{ display: ["none", "contents"] }} >
      <Image src={imgVratnikValue} alt="Videovrátniky Value" sx={{ margin: "auto" }} />
    </Box>
  </Grid>
);

export default VratnikVyzvaKObj;

