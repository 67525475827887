import React, { useState, useEffect } from "react";

import "lightgallery.js/dist/css/lightgallery.css";
import "./myLightGallery.css";

import { LightgalleryProvider } from "react-lightgallery";

const defaultPlugins = [
  "lg-autoplay.js",
  "lg-fullscreen.js",
  "lg-thumbnail.js",
  "lg-video.js",
  "lg-zoom.js",
];

const defaultLightgallerySettings = {
  mode: "lg-fade",
  cssEasing: "ease",
  speed: 600,
  height: "100%",
  width: "100%",
  addClass: "",
  startClass: "lg-start-zoom",
  backdropDuration: 150,
  hideBarsDelay: 6000, // Delay for hiding gallery controls in ms
  useLeft: false, // force lightgallery to use css left property instead of transform.
  closable: true,
  loop: true,
  escKey: true,
  keyPress: true,
  slideEndAnimation: true,
  controls: true, //  If false, prev/next buttons will not be displayed.
  hideControlOnEnd: true, // false If true, prev/next button will be hidden on first/last image.
  // index: 1,
  getCaptionFromTitleOrAlt: true,
  subHtmlSelectorRelative: false, // Set to true if the selector in "data-sub-html" should use the current item as its origin.
  appendSubHtmlTo: ".lg-sub-html",
  ariaLabelledby: "",
  ariaDescribedby: "",
  preload: 1,
  showAfterLoad: true, // Show Content once it is fully loaded
  selector: "", // Custom selector property instead of just child. pass this to select same element ex : .class #id

  selectWithin: "", // By default selectror element is taken from only inside the gallery element.
  nextHtml: "<div>ďalší</div>",
  prevHtml: "<div>predchádzajúci</div>",
  index: 0,
  iframeMaxWidth: "100%",
  download: true,
  counter: true,
  appendCounterTo: ".lg-toolbar",
  swipeThreshold: 50,
  enableDrag: true,
  enableTouch: true,
  dynamic: false /* LightGallery can be instantiated and launched programmatically by setting this option to true and 
populating dynamicEl option (see below) with the definitions of images.*/,
  dynamicEl: [], // An array of objects (src, iframe, subHtml, thumb, poster, responsive, srcset sizes) representing gallery elements.
};
const MyLightGalleryProvider = (props) => {


  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }


  const {
    children,
    galleryClassName,
    myLightgallerySettings = {},
    myPlugins = [],
    ...restProps } = props

  const lightgallerySettings = {
    ...defaultLightgallerySettings,
    ...myLightgallerySettings,
  };
  const plugins = [...defaultPlugins, ...myPlugins];
  return (
    <LightgalleryProvider
      lightgallerySettings={lightgallerySettings}
      galleryClassName={galleryClassName}
      plugins={plugins}
      {...restProps}
    >
      {children}
    </LightgalleryProvider>
  );
};

export default MyLightGalleryProvider;
